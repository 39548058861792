import { useHistory, useLocation } from "react-router-dom";
import { AccessesObj, BasePostureObj, DirectoriesObj, RulesDetailObj, ServerResponse } from "../../../../types/response-types";
import { PlaybookMap, RuleType } from "../../../playbooks/constants/Constants";
import { PostureSearchTemplate, notSupportedKeys } from "../../constants/PostureSearchTemplate"
import { getFilterObjFromTag, getSearchFilterObjectFromTags } from "../../../../components/core/PostureFilter/posture-filter-utils";
import { DefaultActiveTabs, usePostureStateContext } from "../../../../store/PostureStateContextProvider";
import {
	supportedOperators
} from '../../../issue_prev/issues/constant/issueTemplate';
import { useState, useEffect } from 'react';
import { IAMMultiCheckboxMenuItem } from '../../../../components/core/AMMultiCheckbox/AMMultiCheckbox';
import { PostureService } from './PostureService';
import {
	IPostureArchiveRequest,
	IPostureEntityType,
	IPosturearchiveEntity
} from '../types/PostureTypes';
import { CategoriesFields } from "../../../../constants/Categories";
import { GLOBAL_SERVICE } from "../../../services/GlobalService";
import { dateToEpoch, epochToDate, getCategories } from "../../../../utils/util-methods";
import { usePartnerStateContext } from "../../../../store/PartnerContextProvider";
import { ENTITIES, PAGE_TYPES } from "../../constants/Constants";
import { Api } from "../../../../components/Axios";
import axios, { AxiosResponse } from "axios";
import { useToasts } from "../../../../components/core";
import moment from "moment";
import { AVAILABLE_PRESETS, TIME_BASED_SEARCH_FIELD } from "../../../../components/TimeBasedSearch/Constants";
import { useTimeBasedSearchActions } from "../../../../components/TimeBasedSearch/TimeBasedSearchActions";
import { getIssueNameById } from "../../../playbooks/helpers/playbook-helper";
import { GlobalConsts } from "../../../../GlobalConst";

export const formatCSVData = (res: any) => {
	const currDownData = [] as any;
	res.data.result.forEach((item: BasePostureObj) => {
		const issues = item.rules;
		Object.keys(issues).map((i: string) => {
			const o = issues[i] as RulesDetailObj;
			const k = i.replaceAll(' ', '_');
			issues[k] = o;
		});
		currDownData.push(item);
	});
	return currDownData;
};

export const getHeaders = (
	headers: any[],
	issueHeader: string[],
	title: string
) => {
	issueHeader?.forEach((i: string) => {
		const name = getIssueNameById(i);
		headers.push({
			label: `${title} - ${name}`,
			key: `rules.${i.replaceAll(' ', '_')}.issue_count`
		});
	});
	return headers;
};

export const validateLocalIps = (data: any, column: string) => {
	if (data?.result?.length > 0) {
		const l = data?.result?.map((i: any) => {
			if (i[column] !== null) {
				const d = i[column].filter((k: any) => !k.ip.startsWith('127.'));
				i[column] = d;
			}
			return i;
		});
		data.result = l;
		return data;
	}
	return data;
};

export const useScrollIssue = () => {
	const updateColumnEffect = (
		showAnimation: boolean,
		history: any,
		q: any,
		type?: any
	) => {
		if (!showAnimation) return;
		const s = history.location?.state as any;
		const bread = s?.breadcrumbId;
		const playbookPrefix = type === 'identities' ? 'identity_playbooks' :
			type === 'assets' ? 'asset_playbooks' :
				type === 'directory' ? 'directory_playbooks' :
					'';
		if (bread) {
			const queries = q?.split('+') || [];
			if (queries?.length > 0) {
				let issues = queries.find((i: string) =>
					i.includes('rule_name')
				);
				if (!issues) {
					issues = queries.find((i: string) => i.includes(`${playbookPrefix}`));
				}
				if (issues) {
					setTimeout(() => {
						const issuesList = issues
							?.substring(issues.indexOf(':') + 1)
							.split(',');

						const a = Array.from(
							document.querySelectorAll('[data-breadcrumb]')
						) as Array<any>;
						for (let i = 0; i < a.length; i++) {
							const item = a[i] as HTMLElement;
							if (
								item?.getAttribute('data-breadcrumb') ===
								issuesList[0]
							) {
								item?.classList.add('glowing-animation');
							}
						}

					}, 500);

				}
			}
		}
		document
			.getElementsByClassName('ambreadcrumb-container')[0]
			?.classList.add('glowing-bg-animation');
	};

	const scrollToIssueColumnFn = (query: any, q: any, scrollIssueRef: any, type?: any) => {

		const rule = Object.values(RuleType).filter(
			(i: RuleType) => i.toString() === query?.sort_by
		);

		const playbookPrefix = type === 'identities' ? 'identity_playbooks' :
			type === 'assets' ? 'asset_playbooks' :
				type === 'directory' ? 'directory_playbooks' :
					'';

		let queries =
			q?.split('+').length > 0 && q?.split('+')[0]
				? q?.split('+')
				: rule[0]
					? [`rule_name:${rule[0]}`]
					: query?.sort_by ? [`${playbookPrefix}:${query?.sort_by}`] : [''];
		if (queries?.length > 0) {
			setTimeout(() => {
				let issues = queries.find((i: string) => i.includes('rule_name'));
				if (!issues && playbookPrefix) {
					issues = queries.find((i: string) => i.includes(`${playbookPrefix}`));
				}
				if (issues) {
					const issuesList =
						issues?.substring(issues.indexOf(':') + 1)?.split(',') ||
						'';
					if (issuesList?.length == 1) {
						scrollIssueRef.current?.onScrollToViewByRule(issuesList[0]);
					}
				} else {
					scrollIssueRef.current?.onScrollToViewByRule('');
				}

			}, 500)

		}
	};

	return {
		updateColumnEffect,
		scrollToIssueColumnFn
	};
};

export const useLensSearch = (
	searchInput: string,
	setTags: any,
	setSearchInput: any,
	setTagFlag: any
) => {
	const history = useHistory();
	const location = useLocation();
	const handleSearchFn = (
		e: React.KeyboardEvent<HTMLInputElement>,
		tags: Array<string>
	) => {
		const { key } = e;
		const trimmedInput = searchInput?.trim();

		if (
			key === 'Enter' &&
			trimmedInput?.length &&
			!tags?.includes(trimmedInput) &&
			trimmedInput !== ''
		) {
			setTags((prevState: any) => [...prevState, trimmedInput]);
			setSearchInput('');
			setTagFlag(true);
			history.push({
				search: location.search,
				state: {
					breadcrumbId: 'none'
				}
			});
		} else if (
			key === 'Backspace' &&
			tags.length > 0 &&
			trimmedInput.length === 0
		) {
			let tempTags = [...tags];
			tempTags.pop();
			setTags(tempTags);
			setTagFlag(true);
		}
	};

	const handleLensSearchFn = (tags: Array<string>) => {
		const trimmedInput = searchInput?.trim();
		if (
			trimmedInput?.length &&
			!tags?.includes(trimmedInput) &&
			trimmedInput !== ''
		) {
			setTags((prevState: any) => [...prevState, trimmedInput]);
			setSearchInput('');
			setTagFlag(true);
		}
	};

	return {
		handleLensSearchFn,
		handleSearchFn
	};
};

export const usePostureAdvanceSearch = () => {
	const history = useHistory();
	const location = useLocation();
	const { PostureSearchState } = usePostureStateContext();
	const { resetSelectionState } = usePostureArchival();
	const { PartnerConfigState } = usePartnerStateContext()
	const { getPresetActualTimeValue } = useTimeBasedSearchActions();

	const handlePageActivation = (data: any, page: string) => {
		if (!data || Object.keys(data).length === 0) {
			return DefaultActiveTabs;
		}

		let pageTypes = [] as Array<string>;
		let pageTypeArry = [] as Array<string>;
		for (let filter in data) {
			const conf = PostureSearchTemplate.find(
				(i: any) => {
					if (i['page'])
						return i.search_key === filter && i['page'] === page
					else {
						return i.search_key === filter
					}
				}
			) as Record<string, any>;
			if (conf?.pageType) {
				pageTypes.push(conf.pageType);
			}
		}

		if (pageTypes.length > 0) {
			PAGE_TYPES.forEach(item => {
				if (isPageTabEnabled(item, pageTypes))
					pageTypeArry.push(item);
			})
		}

		if (pageTypeArry.length === 1 && pageTypeArry.includes('All')) {
			return {
				identities: true,
				assets: true,
				accesses: true,
				directory: true
			};
		} else {
			return {
				identities: pageTypeArry.includes(ENTITIES.IDENTITY.pageType),
				assets: pageTypeArry.includes(ENTITIES.ASSETS.pageType),
				accesses: pageTypeArry.includes(ENTITIES.ACCESSES.pageType),
				directory: pageTypeArry.includes(ENTITIES.IDENTITY_SYSTEMS.pageType)
			}
		}
	};

	const isPageTabEnabled = (page, pageList) => {
		return pageList.every(item => item.includes(page) || item.includes('All'));
	}

	const getTagKeys = (savedFilters: Array<any>) => {
		const keys = {} as Record<string, { type: string; value: any }>;
		const textBoxSupportedKeys = notSupportedKeys.map((p) => p.search_key);
		const advSearchSupportedKeys = PostureSearchTemplate.map(
			(p) => p.search_key
		);
		const allSearchKeys =
			advSearchSupportedKeys.concat(textBoxSupportedKeys);
		if (savedFilters?.length > 0) {
			for (let filterTag of savedFilters) {
				const [key, val, operator] = getFilterObjFromTag(
					filterTag,
					allSearchKeys
				);
				keys[key] = {
					type: operator,
					value: val
				};
			}
		}
		return keys;
	};

	const handleApplyAdvancedSearch = (
		data: any,
		clearAll: boolean,
		setIsClearAll: any,
		setTags: any,
		setTagFlag: any,
		page: string
	) => {
		history.push({
			search: location.search,
			state: {
				breadcrumbId: 'none'
			}
		});

		if (clearAll) {
			setIsClearAll(clearAll);
			setTags([]);
		} else if (data) {
			if (Object.keys(data).length > 0) {
				// update tags array on Apply AdvanceSearch (Merge only)
				const searchTextList = [] as Array<string>;
				for (let filter in data) {
					let filterValue: any = data[filter]?.value;
					let opr =
						supportedOperators.get(String(data[filter]?.type)) ||
						'';
					if (filter === 'any_field') {
						filterValue.forEach((p: string) => {
							if (p) {
								//addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
								searchTextList.push(p);
							}
						});
						continue;
					}
					//addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
					searchTextList.push(`${filter}${opr}${encodeURIComponent(filterValue)}`);
				}

				if (searchTextList.length > 0) {
					// const merged = Array.from(addedTags.values());// Change Requirement reverting logic               ;
					setTags(searchTextList);
					setTagFlag(true);
				}
			}
		}
		const tabActivated = handlePageActivation(data, page);
		PostureSearchState.setActiveTabs(tabActivated);
		PostureSearchState.setIsAdSearchApplied(true);
	};

	const handleManualSearch = (tags: string[], page: string) => {
		if (tags?.length > 0) {
			const data = getTagKeys(tags);
			const tabActivated = handlePageActivation(data, page);
			PostureSearchState.setActiveTabs(tabActivated);
			PostureSearchState.setIsAdSearchApplied(true);
		} else {
			PostureSearchState.setIsAdSearchApplied(false);
		}
	};

	const getCategoryKeyFromValue = (categoriesList: Array<string>) => {
		let categories = getCategories() as any;
		if (categories) {
			const result = [] as Array<number>;
			categories = JSON.parse(categories);
			for (const key in categories) {
				if (categoriesList.includes(categories[key])) {
					result.push(parseInt(key));
				}
			}
			return result;
		}
		return [];
	};

	const generateTagsFromSearch = async (data: any) => {
		await GLOBAL_SERVICE.Category.GET();
		const searchTextList = [] as Array<string>;
		if (Object.keys(data).length > 0) {
			// update tags array on Apply AdvanceSearch (Merge only)
			for (let filter in data) {
				let filterValue: any = data[filter]?.value;
				let opr =
					supportedOperators.get(String(data[filter]?.type)) || '';
				if (['latest_activity_time', 'first_activity_time'].includes(filter) && opr == 'last_in') {
					filterValue[1] = filterValue[1] == 'day(s)' ? 'day' : 'hour'
				}
				if ([TIME_BASED_SEARCH_FIELD].includes(filter)) {
					filterValue = getPresetActualTimeValue(filterValue);
				}
				if (filter === 'any_field' && filterValue) {
					filterValue.forEach((p: string) => {
						if (p) {
							//addedTags.set(p, p); // set any field value without key. // Change Requirement reverting logic
							searchTextList.push(p);
						}
					});
					continue;
				} else if (CategoriesFields.includes(filter)) {
					filterValue = getCategoryKeyFromValue(filterValue);
				}
				//addedTags.set(filter, `${filter}${opr}${filterValue}`); // Change Requirement reverting logic
				searchTextList.push(`${filter}${opr}${filterValue}`);
			}

			if (searchTextList.length > 0) {
				// const merged = Array.from(addedTags.values());// Change Requirement reverting logic
				return searchTextList;
			}
		}
	};

	const getSearchHeaders = async (param: any) => {
		const searchHeaders = { headers: { search_fields: '' } };
		let searchTags: String[] = [];
		if (param.q && param.q?.split('+').length > 0) {
			const tags = param.q?.split('+').map((i) => decodeURIComponent(i));
			searchTags = tags;
		}
		const fields = getSearchFilterObjectFromTags(PostureSearchTemplate, searchTags);
		const que = await generateTagsFromSearch(fields);
		if ((searchTags || []).length > 0) {
			const searchFieldsList = getSearchFilterObjectFromTags(PostureSearchTemplate, que || [], true);
			searchHeaders.headers.search_fields = encodeURIComponent(JSON.stringify(searchFieldsList));
			// const que = generateTagsFromSearch(searchFieldsList);
			param.q = que?.join('+');
		}
		return searchHeaders;
	}

	const preSetMetaDataHandler = (res) => {
		res.data.dir_type = ['On-premise', 'Cloud IDP'];
		res.data.identity_is_public = ['true', 'false'];
		res.data.asset_is_directory = ['true', 'false'];
		res.data.asset_is_public = ['true', 'false'];
		res.data.asset_is_saas = ['true', 'false'];
		res.data.asset_is_known = ['true', 'false'];
		res.data.identity_is_known = ['true', 'false'];
		res.data.first_activity_time = ['hour(s)', 'day(s)'];
		res.data.latest_activity_time = ['hour(s)', 'day(s)'];
		res.data.id_attrs_change_timestamp = ['hour(s)', 'day(s)'];
		if (res.data.access_playbooks) {
			res.data.access_playbooks = res.data.access_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
		}
		if (res.data.asset_playbooks) {
			res.data.asset_playbooks = res.data.asset_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
		}

		if (res.data.directory_playbooks) {
			res.data.directory_playbooks = res.data.directory_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
		}
		if (res.data.identity_playbooks) {
			res.data.identity_playbooks = res.data.identity_playbooks.map((i) => ({ ...i, key: i.id, value: i.name })).sort((a, b) => a.name.localeCompare(b.name));
		}
		res.data.rule_name = res.data.rule_name.map((i) => ({ key: i, value: getIssueNameById(i) || i }))
		res.data.risk = res.data.risk.map((i) => ({ key: i, value: GlobalConsts.RISK[i] }))
	};

	const preMetaDataHandler = setMetaData => {
		const fetchFilterMetaData = () => {
			return Api.get('/posture/uniquefields');
		};
		fetchFilterMetaData()
			.then(async (res) => {
				preSetMetaDataHandler(res);
				const arr = await GLOBAL_SERVICE.Category.GET();
				const data = { ...res.data, ...arr };
				setMetaData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}


	return {
		handlePageActivation,
		getTagKeys,
		handleApplyAdvancedSearch,
		handleManualSearch,
		generateTagsFromSearch,
		getSearchHeaders,
		PartnerConfigState,
		preMetaDataHandler
	};
};

export const usePostureArchival = (entityType?: IPostureEntityType) => {
	const [selectAll, setSelectAll] = useState<boolean>(false);
	const [selectRow, setSelectRow] = useState<any>({ '0': false });
	const [selectCount, setSelectCount] = useState(0);
	const [selectAllIndeterminate, setSelectAllIndeterminate] = useState(false);
	const [selectedEntities, setSelectedEntities] = useState<
		Record<string, BasePostureObj>
	>({});
	const [openIssuesCount, setOpenIssuesCount] = useState<any>(0);
	const [archivalData, setArchivalData] = useState<ServerResponse>();
	const [isIncidentsOpen, setIsIncidentsOpen] = useState(false);
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState(false);
	const [unselectedEntities, setUnselectedEntities] = useState<
		Record<string, BasePostureObj>
	>({});
	const { Archive, ArchiveEstimate } = PostureService();
	const { addToast } = useToasts()

	const onSelectAllClick = () => {
		if (currentPage) {
			resetSelectionState();
			setSelectAll(true);
			setSelectCount(openIssuesCount);
			selectDeSelectAll(true);
		} else {
			if ((!selectAll && !selectAllIndeterminate)) {
				selectDeSelectAll(true);
				setSelectCount(openIssuesCount);
				setSelectAllIndeterminate(true);
				const resultSet: any = {};
				if (archivalData?.result) {
					Object.values(archivalData?.result).forEach(
						(item: any) =>
							(resultSet[item._id + '_' + (item.type || '')] = item)
					);
					setSelectedEntities(resultSet);
				}
			} else {
				resetSelectionState();
			}
			setSelectAll(
				selectAllIndeterminate ? !selectAllIndeterminate : !selectAll
			);
			setSelectAllIndeterminate(false);
			setCurrentPage(false);
		}
	};
	const PostureMultiSelectCheckboxItems = [
		{
			id: 'current-page',
			callback(evt: unknown) {
				if (currentPage) {
					resetSelectionState();
					return;
				}

				const res = archivalData?.result as Array<BasePostureObj>;
				const selectedIncs = {} as Record<string, boolean>;
				let selEntities = {} as Record<string, BasePostureObj>;
				setCurrentPage(true);
				for (let i = 0; i < res.length; i++) {
					const key =
						res[i]._id.toString() + '_' + (res[i].type || '');
					selectedIncs[key as keyof typeof selectedIncs] = true;
					selEntities = { ...selEntities, ...{ [key]: res[i] } };
				}
				setSelectRow(selectedIncs);
				setSelectCount(Object.keys(selectedIncs).length);
				setSelectAll(false);
				setSelectAllIndeterminate(true);
				setSelectedEntities({ ...selectedEntities, ...selEntities });
			},
			label: 'Current Page'
		},
		{
			id: 'all-pages',
			callback: onSelectAllClick,
			label: 'All Pages'
		}
	] as Array<IAMMultiCheckboxMenuItem>;

	const onSetSelectRow = (e: BasePostureObj) => {
		if (selectAll) {
			setSelectAll(!selectAll);
			setSelectAllIndeterminate(true);
		}
		let selectionArray = { ...selectRow };
		const id = e._id + '_' + (e.type || '');
		selectionArray[id] = !getSelectRow({ _id: e._id, type: e.type });
		setSelectRow(selectionArray);

		if (selectionArray[id]) {
			setUnselectedEntities({
				...unselectedEntities,
				...({ [id]: null } as BasePostureObj | any)
			});
			setSelectedEntities({
				...selectedEntities,
				...({ [id]: e } as BasePostureObj | any)
			});
		} else {
			setUnselectedEntities({
				...unselectedEntities,
				...({ [id]: e } as BasePostureObj | any)
			});
			setSelectedEntities({
				...selectedEntities,
				...({ [id]: null } as BasePostureObj | any)
			});
		}
		if (currentPage && selectAllIndeterminate) {
			const negativeCount =
				Object.values(selectionArray).filter((el) => !el).length - 1;
			const selection = Object.values(selectionArray).filter(
				(el) => el
			).length;
			setSelectCount(selection);
			if (selection == 0) {
				setSelectAllIndeterminate(false);
				setSelectAll(false);
				selectDeSelectAll(false);
			}
		} else if (selectAll || selectAllIndeterminate) {
			const negativeCount =
				Object.values(selectionArray).filter((el) => !el).length - 1;
			const selection = Object.values(selectionArray).filter(
				(el) => el
			).length;
			const count = openIssuesCount - negativeCount;
			setSelectCount(count);
			if (selectAllIndeterminate && count == openIssuesCount) {
				/* Part of https://authmind.atlassian.net/browse/A1Y-1511 */
				// setSelectAll(true);
				// setSelectAllIndeterminate(false);
			} else if (
				(selectAllIndeterminate && count == 0) ||
				selection == 0
			) {
				setSelectAllIndeterminate(false);
				setSelectAll(false);
				selectDeSelectAll(false);
			} else if (count < 0) {
				setSelectCount(selection);
			}
			setCurrentPage(false);
		} else {
			const select = Object.values(selectionArray).filter(
				(el) => el
			).length;
			setSelectCount(select);
			if (select == openIssuesCount) {
				setSelectAll(true);
			}
			setCurrentPage(false);
		}

	};

	const selectDeSelectAll = (flag: boolean) => {
		if (archivalData?.result) {
			let selectionArray = { ...selectRow };
			archivalData.result.forEach((item: any) => {
				selectionArray[item._id + '_' + (item.type || '')] = flag;
			});
			setSelectRow(selectionArray);
		}
	};



	const getSelectRow = (e: Partial<BasePostureObj>) => {
		const id = e?._id + '_' + (e.type || '');
		if (selectAllIndeterminate && currentPage) {
			if (selectRow.hasOwnProperty(id)) {
				return selectRow[id];
			}
			return false;
		} else if (selectAll) {
			return true;
		} else if (selectRow.hasOwnProperty(id) && selectAllIndeterminate) {
			return selectRow[id];
		} else if (selectRow.hasOwnProperty(id)) {
			return selectRow[id];
		} else if (selectAllIndeterminate) {
			return selectRow[id] || true;
		}
		return false;
	};

	const generateEntityList = (
		entityMap: Record<string, BasePostureObj>
	): Array<IPosturearchiveEntity> => {
		let entity_list: Array<Partial<IPosturearchiveEntity>> = [];
		switch (entityType) {
			case 'Identity':
				{
					entity_list = Object.values(entityMap)
						.map((value: BasePostureObj) => {
							if (value) {
								return {
									identity: value._id,
									identity_type: value?.type
								};
							}
							return null;
						})
						.filter(
							(val: IPosturearchiveEntity | null) => val
						) as Array<IPosturearchiveEntity>;
				}
				break;
			case 'Asset':
				{
					entity_list = Object.values(entityMap)
						.map((value: BasePostureObj) => {
							if (value) {
								return {
									asset: value._id,
									asset_type: value?.type
								};
							}
							return null;
						})
						.filter(
							(val: IPosturearchiveEntity | null) => val
						) as Array<IPosturearchiveEntity>;
				}
				break;
			case 'Access':
				{
					entity_list = Object.values(entityMap)
						.map((val: BasePostureObj) => {
							const value: AccessesObj = Object.assign(
								{},
								val
							) as AccessesObj;
							if (value.identity) {
								return {
									identity: value.identity,
									identity_type: value?.identity_type,
									asset: value.asset,
									asset_type: value.asset_type,
									...((value.directory_hostname ||
										value.directory_name) && {
										directory_name:
											value.directory_hostname ||
											value.directory_name
									})
								};
							}
							return null;
						})
						.filter(
							(val: IPosturearchiveEntity | null) => val
						) as Array<IPosturearchiveEntity>;
				}
				break;
			case 'Directory':
				{
					entity_list = Object.values(entityMap)
						.map((value: BasePostureObj) => {
							if (value) {
								return {
									directory_name: value._id
								};
							}
							return null;
						})
						.filter(
							(val: IPosturearchiveEntity | null) => val
						) as Array<IPosturearchiveEntity>;
				}
				break;
		}
		return entity_list;
	};

	const onArchive = (reload?: any) => {
		let request: Partial<IPostureArchiveRequest> = {};
		if (currentPage) {
			let entity_list: Array<any> = generateEntityList(selectedEntities);
			request = {
				archive_option: '1',
				entity_list,
				entity_type: entityType
			};
		} else if (selectAll || selectAllIndeterminate) {
			const search = new URLSearchParams(location.search);
			const paramsList = search.get('q');
			let search_query;
			let unSelectRow = generateEntityList(unselectedEntities);
			if (paramsList) {
				search_query = getSearchFilterObjectFromTags(PostureSearchTemplate,
					paramsList?.split('+')
				) as Record<string, { type: string; value: unknown }>;
			} else {
				search_query = '';
			}
			request = {
				archive_option: '2',
				entity_type: entityType,
				search_query,
				...(unSelectRow?.length > 0 && {
					exclude_entity_list: unSelectRow
				})
			};
		} else {
			let entity_list: Array<any> = generateEntityList(selectedEntities);
			request = {
				archive_option: '1',
				entity_list,
				entity_type: entityType
			};
		}

		Archive.POST(request, () => {
			resetSelectionState();
			if (reload) reload();
		});
	};

	const onArchiveEstimate = (successCallbackFunc: any, errorCallbackFunc: any): void => {
		let request: Partial<IPostureArchiveRequest> = {};
		if (currentPage) {
			let entity_list: Array<any> = generateEntityList(selectedEntities);
			request = {
				archive_option: '1',
				entity_list,
				entity_type: entityType
			};
		} else if (selectAll || selectAllIndeterminate) {
			const search = new URLSearchParams(location.search);
			const paramsList = search.get('q');
			let search_query;
			let unSelectRow = generateEntityList(unselectedEntities);
			if (paramsList) {
				search_query = getSearchFilterObjectFromTags(PostureSearchTemplate,
					paramsList?.split('+')
				) as Record<string, { type: string; value: unknown }>;
			} else {
				search_query = '';
			}
			request = {
				archive_option: '2',
				entity_type: entityType,
				search_query,
				...(unSelectRow?.length > 0 && {
					exclude_entity_list: unSelectRow
				})
			};
		} else {
			let entity_list: Array<any> = generateEntityList(selectedEntities);
			request = {
				archive_option: '1',
				entity_list,
				entity_type: entityType
			};
		}

		if (successCallbackFunc && errorCallbackFunc) ArchiveEstimate.POST(request, successCallbackFunc, errorCallbackFunc);

	};

	useEffect(() => {
		if (selectAll || selectAllIndeterminate) {
			setIsIncidentsOpen(true);
		} else if (Object.keys(selectRow).length > 0) {
			let incidentCount = 0;
			Object.values(selectedEntities).forEach((row: BasePostureObj) => {
				if (row?.issue_count > 0 || false) {
					incidentCount += row.issue_count;
				}
			});
			if (incidentCount > 0) {
				setIsIncidentsOpen(true);
			} else {
				setIsIncidentsOpen(false);
			}
		} else {
			setIsIncidentsOpen(false);
		}
	}, [selectAll, selectAllIndeterminate, selectedEntities, selectRow]);

	const resetSelectionState = () => {
		setSelectAllIndeterminate(false);
		setSelectAll(false);
		setSelectRow({ '0': false });
		setSelectCount(0);
		setUnselectedEntities({});
		setSelectedEntities({});
		setCurrentPage(false);
	};

	const onBulkDirectoriesAdd = () => {
		const apis: Array<Promise<AxiosResponse<any, any>>> = [];
		if (selectedEntities && Object.entries(selectedEntities).length > 0) {
			const entities = Object.values(selectedEntities).filter(entity => entity);
			if (entities?.length > 0) {
				for (let i = 0; i < entities?.length; i++) {
					const item = entities[i] as DirectoriesObj;
					const name = item._id;
					const result: string[] = [];
					item.host_detail.forEach((item: any) => {
						if (item?.ip?.length > 0) result.push(item.ip);
						if (item?.hostname?.length > 0) result.push(item.hostname);
					});
					if (result.length == 0) {
						result.push(item._id);
					}
					const ips = Array.from(new Set(result));

					const request = {
						name, ips
					};
					apis.push(Api.post('/directories', request));
				}
			}
		}
		axios.all(apis).then(() => {
			addToast("Assets have been added as known Identity Systems.", {
				appearance: 'success',
				autoDismiss: true,
			});
			resetSelectionState()
		}).catch(() => {
			addToast("One of entered IP/FQDN is already used in other configured Identity System", {
				appearance: 'error',
				autoDismiss: true,
			})
		});
	}


	return {
		selectAll,
		setSelectAll,
		selectRow,
		setSelectRow,
		onSetSelectRow,
		getSelectRow,
		onSelectAllClick,
		selectAllIndeterminate,
		setArchivalData,
		setOpenIssuesCount,
		onArchive,
		selectCount,
		isIncidentsOpen,
		PostureMultiSelectCheckboxItems,
		resetSelectionState,
		onArchiveEstimate,
		setCurrentPage,
		currentPage,
		onBulkDirectoriesAdd
	};
};