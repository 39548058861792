export const dashboardTemplate = {
    rows: [
        {
            height: 10,
            widgets: [
                {
                    id: 'wid0_0',
                    title: "",
                    type: "24hrs_incidents",
                    dataKey: "past_24_hours_flows",
                    ruleCategory: "",
                    styles: {
                        flexBasis: '29%',
                        height: '100%',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        marginRight: '8px',
                        padding: '0px 0px'
                    },
                    filterKey: 'none'
                },
                {
                    id: 'wid0_1',
                    title: "",
                    type: "risk_trend",
                    dataKey: "irl",
                    ruleCategory: "",
                    styles: {
                        padding: '0px',
                        height: '100%'
                    },
                    filterKey: 'none'
                },
            ],
        },
        {
            height: 18,
            widgets: [
                {
                    id: 'wid1_0',
                    title: "",
                    shadowBorder: false,
                    type: "top_new_risky",
                    dataKey: "top_focus_things",
                    filterKey: 'top_new_risky'
                }
            ],
        },
        {
            height: 31,
            widgets: [
                {
                    id: 'widxxx',
                    title: "",
                    shadowBorder: false,
                    type: "recent_access",
                    dataKey: "recent_issues",
                    styles: { padding: '0px', height: '100%' },
                    filterKey: 'recent_issues'
                }
            ],
        },
        {
            height: 31,
            widgets: [
                {
                    id: 'wid2_0',
                    title: "ID and Access infrastructure",
                    shadowBorder: false,
                    type: "donut_chart",
                    dataKey: "rules",
                    ruleCategory: "id_access_infra",
                    filterKey: 'id_access_infra',
                    styles: {
                        flexBasis: '33.33%'
                    }
                },
                {
                    id: 'wid2_1',
                    title: "Shadow Activity",
                    shadowBorder: false,
                    type: "donut_chart",
                    dataKey: "rules",
                    ruleCategory: "shadow_activity",
                    filterKey: 'shadow_activity',
                    styles: {
                        flexBasis: '33.33%'
                    }
                },
                {
                    id: 'wid2_2',
                    title: "Unauthorized Access",
                    shadowBorder: false,
                    type: "donut_chart",
                    dataKey: "rules",
                    ruleCategory: "unauthorised_access",
                    filterKey: 'unauthorised_access',
                    styles: {
                        flexBasis: '33.33%'
                    }
                },
            ],
        },
        {
            height: 18,
            widgets: [
                {
                    id: 'wid3_0',
                    title: "Credential Risks",
                    type: "blocks",
                    dataKey: "rules",
                    ruleCategory: "credentials_risk",
                    filterKey: 'credentials_risk',
                    styles: {
                        flexBasis: '32.7%'
                    }
                },
                {
                    id: 'wid3_1',
                    title: "Unknown Access",
                    type: "unknown_access",
                    dataKey: "rules",
                    ruleCategory: "unknown_access",
                    filterKey: 'unknown_access',
                    styles: {
                        flexBasis: '32.7%'
                    },
                    isCustomTitle: true
                },
                {
                    id: 'wid3_2',
                    title: "Risky Protocols",
                    type: "risky_protocols",
                    dataKey: "protocols",
                    filterKey: 'protocols',
                    styles: {
                        flexBasis: '32.7%'
                    }
                },
            ],
        },
        {
            height: 20,
            widgets: [
                {
                    id: 'wid4_0',
                    title: "Incident Trend",
                    type: "issue_trend",
                    dataKey: "last_24_hours_issues",
                    filterKey: 'issue_trend'
                },
            ],
        },
    ],
};
