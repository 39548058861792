import moment from "moment";
import { getIssueNameById } from "../pages/playbooks/helpers/playbook-helper";

export const getRoundedUnit = (count: number | undefined) => {
    if (count !== undefined) {
        let str = count.toString().split('.')[0];
        if (str.length > 3 && str.length <= 6) { // thousands
            return str.substr(0, str.length - 3).concat("." + str.charAt(1) + 'K');
        }
        else if (str.length > 6 && str.length <= 9) { // millions
            return str.substr(0, str.length - 6).concat("." + str.charAt(1) + 'M');
        }
        else if (str.length > 9) { // billions
            return str.substr(0, str.length - 9).concat("." + str.charAt(1) + 'B');
        }
        return str;
    } else {
        return 0;
    }
}


export const findDifferenceInDays = (givenDateString: any): number => {

    const givenDate: Date = new Date(givenDateString.toString());
    const currentDate: Date = new Date();
    const differenceInMilliseconds: number = currentDate.getTime() - givenDate.getTime();
    const differenceInDays: number = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
}

export const getRoundedUnitWithoutDot = (count: number | undefined) => {
    if (count !== undefined) {
        let str = count.toString().split('.')[0];
        if (str.length > 3 && str.length <= 6) { // thousands
            //if rounded count is more than 99 with decimal, then remove decimal
            if (parseInt(str.substr(0, str.length - 3)) > 99)
                return str.substr(0, str.length - 3).concat('K');
            else
                return str.substr(0, str.length - 3).concat("." + str.charAt(1) + 'K');
        }
        else if (str.length > 6 && str.length <= 9) { // millions
            //if rounded count is more than 99 with decimal, then remove decimal
            if (parseInt(str.substr(0, str.length - 6)) > 99)
                return str.substr(0, str.length - 6).concat('M');
            else
                return str.substr(0, str.length - 6).concat("." + str.charAt(1) + 'M');
        }
        else if (str.length > 9) { // billions
            //if rounded count is more than 99 with decimal, then remove decimal
            if (parseInt(str.substr(0, str.length - 9)) > 99)
                return str.substr(0, str.length - 9).concat('B');
            else
                return str.substr(0, str.length - 9).concat("." + str.charAt(1) + 'B');
        }
        return str;
    }
}

export const getRiskLevelColor = (color: number | undefined) => {
    if (color === 0) {
        return "#ffffff";
    } else if (color === 1) {
        return "#D4D8E1";
    } else if (color === 2) {
        return "#FCBF2E";
    } else if (color === 3) {
        return "#FD7122";
    } else if (color === 4) {
        return "#FA1262";
    }
    else {
        return "#ffffff"
    }
}

export const getRuleFromIssueIndex = (index: number) => {
    if (index === 0) {
        return 'Authentication Quality';
    } else if (index === 1) {
        return 'Compromised Password';
    } else if (index === 2) {
        return 'Compromised User';
    } else if (index === 3) {
        return 'Exposed Assets';
    } else if (index === 4) {
        return 'Shadow External Access';
    } else if (index === 5) {
        return 'Auth Hash Security';
    } else if (index === 6) {
        return 'Auth Hash Quality';
    } else if (index === 7) {
        return 'Lack of MFA';
    } else if (index === 8) {
        return 'Auth Protocol Quality';
    } else if (index === 9) {
        return 'Shadow Access';
    } else if (index === 10) {
        return 'Shadow Assets';
    } else if (index === 20) {
        return 'Shadow Identity Systems';
    } else if (index === 11) {
        return 'Suspicious Inbound Access';
    } else if (index === 12) {
        return 'Suspicious Outbound Access';
    } else if (index === 13) {
        return 'Unknown Access';
    } else if (index == 14) {
        return "Unknown SaaS Access"
    } else if (index == 15) {
        return 'Weak Password'
    }
}

export const isEmpty = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
}

export const parseJwt = (token: any) => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (err: any) {
        return null
    }
}

export const removeDuplicates = (arr: any[], key: any) => {
    return arr.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t[key] === value[key]
        ))
    );
}

export const convertToCommaValue = (value: any) => {
    if (value)
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return value;
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string | undefined): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
}

export const isJsonString = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    const jsonString = JSON.parse(str);

    if (isEmpty(jsonString)) {
        return false;
    }
    return true;
}

export const getRuleName = (ruleId: string) => {
    const map = {
        "authentication_hash_quality": "Auth Hash Quality",
        "authentication_hash_security": "Auth Hash Security",
        "authentication_protocol_quality": "Auth Protocol Quality",
        "compromised_password": "Compromised Password",
        "compromised_user": "Compromised User",
        "exposed_assets": "Exposed Assets",
        "lack_of_mfa": "Lack of MFA",
        "shadow_access": "Shadow Access",
        "shadow_assets": "Shadow Assets",
        "shadow_external_access": "Shadow External Access",
        "suspicious_inbound_access": "Suspicious Inbound Access",
        "suspicious_outbound_access": "Suspicious Outbound Access",
        "weak_password": "Weak Password",
        "unknown_saas_access": "Unknown SaaS Access",
        "shadow_directory": 'Shadow Identity Systems'
    };
    const rulename = map[ruleId as keyof typeof map];
    return rulename ? rulename : getIssueNameById(ruleId);
}

export const epochToDate = function (EPOC: any) {
    try {
        if (EPOC) {
            const date = new Date(EPOC * 1000); //received timestamp in seconds, convert it to miliseconds.
            return date;
        }
        return "";
    } catch (error) {
        console.log(error);
        return "";
    }
};

export const dateToEpoch = function (dateString: string) {
    try {
        const dateVal = new Date(dateString);
        const epochVal = new Date(dateVal.toUTCString()).getTime();
        return epochVal / 1000; // send timestamp in seconds.
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const trimAfterSecondWord = (strVal: string, len: number): string => (strVal.length > len) ? strVal?.substring(0, len) + '...' : strVal;

export const withThrottle = (fn: Function, delay: number) => {
    let canCall = true;
    return function (arg: any) {
        if (canCall) {
            fn(arg);
            canCall = false;
            setTimeout(() => {
                canCall = true;
            }, delay);
        }
    }
}


export const withDebounce = (fn: Function, delay: number) => {
    let timer: any;
    return function (...args: any) {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay)
    }
}

export const addDaysToEpoc = (dateInMs: number, count: number) => {
    try {
        return dateInMs + (count * 86400000);
    }
    catch (error) {
        console.log(error);
        return dateInMs;
    }
}
export const addMonthsToEpoc = (dateInMs: number, count: number) => {
    try {
        return dateInMs + (count * 2419200000);
    }
    catch (error) {
        console.log(error);
        return dateInMs;
    }
}

export const addMinsToEpoc = (dateInMs: number, count: number) => {
    try {
        return dateInMs + (count * 60000);
    }
    catch (error) {
        console.log(error);
        return dateInMs;
    }
}


export const addHoursToEpoc = (dateInMs: number, count: number) => {
    try {
        return dateInMs + (count * 3600000);
    }
    catch (error) {
        console.log(error);
        return dateInMs;
    }
}



export const getTimeDiffString = (startFromDate: number, endFromDate?: number): string | undefined => {
    try {
        let df = endFromDate ? moment(endFromDate).diff(moment(startFromDate), 'minute') : moment().diff(moment(startFromDate), 'minute');
        if (df === 0) {
            return '';
        }

        let unit = '';
        if (df <= 60 && df > 0) {
            unit = 'minute';
        }
        else if (df < 1440 && df > 60) {
            unit = 'hour';
            df = df / 60;
        }
        else {
            unit = 'day';
            df = df / (60 * 24);
        }

        df = Math.floor(df);
        return endFromDate ? `${df} ${(df === 1) ? unit : unit + 's'}` : `Last ${df} ${(df === 1) ? unit : unit + 's'}`;
    }
    catch (error) {
        console.log(error);
        return '';
    }
}

export const formatDate = (dt: string) => {
    moment.locale('en');
    if (typeof (dt) == "undefined") {
        return null;
    } else {
        return moment(dt).format('MMM DD, YYYY h:mm A');
    }
}

export const getRiskTitleByIndex = (color: number | undefined) => {
    if (color === 0) {
        return "No Risk available";
    } else if (color === 1) {
        // low
        return "Low"
    } else if (color === 2) {
        // medium
        return "Medium"
    } else if (color === 3) {
        // high
        return "High"
    } else if (color === 4) {
        // Critical
        return "Critical"
    }
    else {
        return "No Risk available"
    }
}

export const getResolutionMatch = (higherValue: number, lowerValue: number) => {
    return window.matchMedia("(min-width: 2560px)").matches ? lowerValue : lowerValue;
}
export const deepCopyFunction = (inObject: any) => {
    let outObject, value, key;

    if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {} as any;

    for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key as keyof typeof outObject] = deepCopyFunction(value);
    }

    return outObject;
};

export const getIssueTypeIconClass = (issueName: string = '') => {
    //const randomIconId = Math.floor(Math.random() * 6);
    switch (issueName) {
        // Vulnerable risk icon
        case 'Auth Hash Quality':
        case 'Auth Hash Security':
        case 'Exposed Assets':
        case 'Weak Password': return 'vulnerable-icon';

        // Compromised risk icon
        case 'Compromised Password': return 'compromised-icon';

        // Unmanaged risk icon
        case 'Shadow Access':
        case 'Shadow Assets':
        case 'Shadow Identity Systems': return 'unmanaged-icon';

        // Unknown risk icon
        case 'Shadow External Access':
        case 'Unknown SaaS Access': return 'unknown-icon';

        // Suspicious risk icon
        case 'Suspicious Inbound Access':
        case 'Suspicious Outbound Access': return 'suspicious-icon';

        // Unauthorized risk icon
        case 'Access to Unauthorized Countries':
        case 'Access from Unauthorized Countries':
        case 'Access to Public VPN':
        case 'Access from Public VPN':
        case 'Access to Anonymous IP':
        case 'Access from Anonymous IP':
        case 'Unauthorized Asset Access': return 'unauthorized-icon';

        default: return 'suspicious-icon';

        // case 0: return 'compromised-icon';
        // case 1: return 'suspicious-icon';
        // case 2: return 'unauthorized-icon';
        // case 3: return 'unknown-icon';
        // case 4: return 'vulnerable-icon';
        // case 5: return 'unmanaged-icon';
        // default: return 'unknown-icon';
    }
}

export const getIssueTypeTitle = (issueName: string = '') => {
    //const randomIconId = Math.floor(Math.random() * 6);
    switch (issueName) {
        // Vulnerable risk icon
        case 'Auth Hash Quality':
        case 'Auth Hash Security':
        case 'Exposed Assets':
        case 'Weak Password': return 'vulnerable';

        // Compromised risk icon
        case 'Compromised Password': return 'compromised';

        // Unmanaged risk icon
        case 'Shadow Access':
        case 'Shadow Assets':
        case 'Shadow Identity Systems': return 'unmanaged';

        // Unknown risk icon
        case 'Shadow External Access':
        case 'Unknown SaaS Access': return 'unknown';

        // Suspicious risk icon
        case 'Suspicious Inbound Access':
        case 'Suspicious Outbound Access': return 'suspicious';

        // Unauthorized risk icon
        case 'Access to Unauthorized Countries':
        case 'Access from Unauthorized Countries':
        case 'Access to Public VPN':
        case 'Access from Public VPN':
        case 'Access to Anonymous IP':
        case 'Access from Anonymous IP':
        case 'Unauthorized Asset Access': return 'unauthorized';

        default: return 'suspicious';

        // case 0: return 'compromised-icon';
        // case 1: return 'suspicious-icon';
        // case 2: return 'unauthorized-icon';
        // case 3: return 'unknown-icon';
        // case 4: return 'vulnerable-icon';
        // case 5: return 'unmanaged-icon';
        // default: return 'unknown-icon';
    }
}

export const getBreadCrumbTitleOnRuleType = (ruleType: string = '') => {
    switch (ruleType) {
        case "Shadow External Access":
            return '';
        case "Shadow Access":
            return "All assets having Shadow Accesses";

        case "Shadow Identity Systems":
            return "All Shadow Identity Systems";

        case "Suspicious Inbound Access":
            return "All assets with Suspicious Inbound Accesses";

        case "Suspicious Outbound Access":
            return "All identities with Suspicious Outbound Accesses";
        default: return '';
    }
}

export const scrambleIt = (list: any[]) => {
    try {
        const src = [...list];
        const scrambledList = [];

        while (src.length > 0) {
            const randomIndex = Math.floor(Math.random() * (src.length - 1));
            scrambledList.push(src.splice(randomIndex, 1)[0]);
        }
        return scrambledList;
    }
    catch (error) {
        console.log(error);
        return list;
    }

}

export function getCustomLabelForRuleName(name: string) {
    switch (name) {
        case 'Access from Unauthorized Countries': return 'From Unauth. Countries'
        case 'Access to Unauthorized Countries': return 'To Unauth. Countries';
        case 'Access to Anonymous IP': return 'To Anon. IP Access';
        case 'Access from Anonymous IP': return 'From Anon. IP Access';
        case 'Access to Public VPN': return 'To Public VPN Access';
        case 'Access from Public VPN': return 'From Public VPN Access';
        case 'Deviation in Daily Asset Activity': return 'Deviation in Access';
        case 'Suspected Directory/IdP Bot Attack': return 'Directory Bot Attack';
        case 'Suspected Directory/IdP Identity Brute-force Attack': return 'Brute-force Attack';
        case 'Suspected Directory/IdP Password Spray Attack': return 'Password Spray Attack';
        case 'Suspected AD NTLM Relay Attack': return 'NTLM Relay Attack';
        case 'Enumeration of AD Admins': return 'Admin Enumeration';
        case 'Enumeration of AD Users': return 'User Enumeration';
        case 'Repeated AD Login Attempts at Invalid Time': return 'Invalid Login Time';
        case 'Repeated AD Login Attempts from Invalid Device': return 'Invalid Login Device';
        case 'Suspected Attack on Expired AD Account': return 'Expired Account/Password Attack';
        case 'Suspected Attack on Disabled AD Account': return 'Disabled Account Attack';
        case 'Suspected Attack on Locked AD Account': return 'Locked Account Attack';
        case 'Suspected AD Golden Ticket Attack': return 'Golden Ticket Attack';
        case 'Suspected AD Pass-the-Ticket Attack': return 'Pass-the-Ticket Attack';
        default: return name
    }
}

export const isValidIp = (ip: string): boolean => {
    const pattern = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    return pattern.test(ip);
}

export const isPrivateIp = (ip: string): boolean => {
    const pattern = /(^192\.168\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])$)|(^172\.([1][6-9]|[2][0-9]|[3][0-1])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])$)|(^10\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])\.([0-9]|[0-9][0-9]|[0-2][0-5][0-5])$)/
    return pattern.test(ip);
}

export const isValidPort = (v: string) => {
    return /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(v);
}

export const convertToPascal = (value: String = ''): string => {
    if (value === null || value === '' || value === undefined) {
        return "";
    }
    const pascalText = value.replace(/\w+/g, function (w) { return w[0].toUpperCase() + w.slice(1); });
    return pascalText;
}

const isValidMac = (mac: string): boolean => {
    const pattern = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!pattern.test(mac.trim())) {
        return false;
    }
    return true;
}

export const isValidMACList = (macList: string): boolean => {
    const macAddresses = macList && typeof macList == 'string' ? macList?.split(',') : macList && Array.isArray(macList) ? macList : [];
    for (const mac of macAddresses) {
        if (!isValidMac(mac)) {
            return false;
        }
    }
    return true;
}


export const isValidPartialMac = (macList: string): boolean => {
    const macAddresses = macList && typeof macList == 'string' ? macList?.split(',') : macList && Array.isArray(macList) ? macList : [];
    const expr = /^[0-9A-Fa-f]{1,2}(?:[:-][0-9A-Fa-f]{0,2}){0,5}$/;
    for (const mac of macAddresses) {
        let m = mac;
        if (isValidMac(m)) {
            continue;
        }
        let expExists = false;
        if (mac[mac.length - 1] == '*') {
            m = mac.slice(0, mac.length - 1);
            expExists = true;
        }
        if (!expExists) {
            return false;
        }
        if (!expr.test(m.trim())) {
            return false;
        }
    }
    return true;
}

export const flattenObject = (ob) => {
    var toReturn = {};
    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

export const deepCompare = (obj1: any, obj2: any) => {

    //Loop through properties in object 1
    for (var p in obj1) {
        //Check property exists on both objects
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

        switch (typeof (obj1[p])) {
            //Deep compare objects
            case 'object':
                if (!deepCompare(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case 'function':
                if (typeof (obj2[p]) == 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString())) return false;
                break;
            //Compare values
            default:
                if (obj1[p] != obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (var p in obj2) {
        if (typeof (obj1[p]) == 'undefined') return false;
    }
    return true;
}

const compareByField = (a, b, fieldName) => {
    if (a[fieldName] < b[fieldName]) {
        return -1;
    }
    if (a[fieldName] > b[fieldName]) {
        return 1;
    }
    return 0;
}


export const arrayOfObjectDeepCompare = (arr1: Array<any>, arr2: Array<any>, sortField?: string) => {
    let a1 = arr1, a2 = arr2;
    if (sortField) {
        const tempA1 = [...arr1];
        const tempA2 = [...arr2];
        tempA1.sort((a, b) => compareByField(a, b, sortField));
        tempA2.sort((a, b) => compareByField(a, b, sortField));
        a1 = tempA1;
        a2 = tempA2;
    }
    return a1.length === a2.length && a1.every((o, idx) => deepCompare(o, a2[idx]));
}

const objectsEqual = (o1: any, o2: any): any =>
    typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

export const arraysEqual = (a1: any, a2: any): any =>
    a1.length === a2.length && a1.every((o: any, idx: any) => objectsEqual(o, a2[idx]));

export const getCategoryByKey = (key: string | number) => {
    const rawCategories = getCategories();
    if (rawCategories) {
        const categories = JSON.parse(rawCategories) as Record<string | number, string>;
        const category = categories[key];
        return category;
    }
    return key;
}

export const getCategories = () => {
    return sessionStorage.getItem('categories');
}

export const generateCategoriesForValues = (values: any) => {
    let categories = getCategories() as any;
    if (categories && values?.length) {
        const result = [] as Array<number>;
        categories = JSON.parse(categories);
        for (const key in categories) {
            if (values.includes(categories[key])) {
                result.push(parseInt(key));
            }
        }
        return result;
    }
    return [];
};


export const generateCategoriesForResponse = (values: any) => {
    let categories = getCategories() as any;
    if (categories && values?.length) {
        const result = [] as Array<string>;
        categories = JSON.parse(categories);
        for (const key in categories) {
            if (values.includes(parseInt(key))) {
                result.push(categories[key]);
            }
        }
        return result;
    }
    return [];
};


export const isIssueType = (array: any[], name: string): boolean => {
    for (const obj in array) {
        if (array.hasOwnProperty(obj)) {
            const foundItem = array[obj]?.playbooks?.find((item: any) => item._id === name);
            if (foundItem) {
                return false;
            }
        }
    }
    return true;

}

export const findNameById = (selectedItems: any, idToFind: string): string => {
    for (const obj in selectedItems) {
        if (selectedItems.hasOwnProperty(obj)) {
            const foundItem = selectedItems[obj]?.playbooks?.find((item: any) => item._id === idToFind);
            if (foundItem) {
                return foundItem.name;
            }
        }
    }
    return idToFind;
}

export const getTitleOnEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return 'identities';
        case 'asset': return 'assets';
        case 'directory': return 'directories';
        default: return 'entities';
    }
}

const removeExtraQuotes = (str) => {
    // Check if the string starts and ends with a double quote
    if (str.startsWith('"') && str.endsWith('"')) {
        // Remove the double quotes
        return str.slice(1, -1);
    }
    return str;
};


export const parseFilterInput = (str, removeQuotes?) => {
    const regex = /("[^"]*"|[^,]+)/g;
    const matches = str.match(regex);
    const result: Array<string> = [];

    if (!matches) return [];

    for (let i = 0; i < matches.length; i++) {
        if (matches[i].startsWith('"') && matches[i].endsWith('"')) {
            // Preserve quoted substring intact
            if (removeQuotes) {
                result.push(removeExtraQuotes(matches[i]))
            } else {
                result.push(matches[i]);
            }
        } else {
            // Split non-quoted segments by comma
            const splitSegment = matches[i].split(/,/).filter(Boolean);
            result.push(...splitSegment);
        }
    }

    return result;
};

export const isAmopsUser = (userEmail: string): boolean => {

    if (userEmail === "" || userEmail === undefined || userEmail === null) return false;
    if (userEmail === 'amops@am.net') {
        return true;
    }
    return userEmail.toString().startsWith("amops@") && userEmail.toString().endsWith(".authmind");
}

export const getExportFileDateTime = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}`;
}

export const getLocalUser = () => {
    const userDetails = localStorage.getItem('user') as string;
    const user = JSON.parse(userDetails);
    return user;
}

export const getExportFileName = (shortProduct: string, entityName: string) => {
    const productName = shortProduct.replaceAll(' ', '_')
    const exportTme = getExportFileDateTime();
    const u = getLocalUser();
    let tenantName = '';
    if (u && isAmopsUser(u.email)) {
        tenantName = u.tenantName.replaceAll(' ', '_');
        return `${productName}_${tenantName}_${entityName}_${exportTme}.csv`
    }
    return `${productName}_${entityName}_${exportTme}.csv`
}

export const getCurrentDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1);
}


export const syntaxHighlight = (json)=> {
  if (!json) return ""; //no JSON from response

  const parsedJson = JSON.parse(json);

  for (const key in parsedJson) {
    if (key === "sourceType" && typeof parsedJson[key] === "string" && !parsedJson[key].startsWith("user")) {
      parsedJson[key] = "NHI-" + parsedJson[key].replace('Service/Application', 'Application')
      .replace('Service/Service Account', 'Service');
    }
  }


  const modifiedJson = JSON.stringify(parsedJson, null, 6);

  // Highlight syntax
  return modifiedJson.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return `<span class="${cls}">${match}</span>`;
    }
  );
}