import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Highcharts from 'highcharts';
import broken from 'highcharts/modules/broken-axis';
import HighchartsReact from 'highcharts-react-official';
import { IdAssetWidget } from '../../../types/response-types';
import { getResolutionMatch, getRoundedUnit } from '../../../utils/util-methods';
import HC_patternFill from "highcharts/modules/pattern-fill";
import { PostureSearchTemplate } from '../constants/PostureSearchTemplate';
import { getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';

HC_patternFill(Highcharts);
//broken(Highcharts);

interface Custom_Data {
	"title": string,
	"subtitle": string,
	"totalCount": number | undefined,
	"issueCount": number | undefined,
	"is_exclude": boolean,
}

type Props = {
	customData: Custom_Data,
	issues: IdAssetWidget | undefined
}

export const ReportByIdAsset = ({ customData, issues }: Props) => {
	let history = useHistory();
	const [chartInnerData, setChartInnerData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartOuterData, setChartOuterData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartInnerCloneData, setChartInnerCloneData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartInnerEmptyIssues, setChartInnerEmptyIssues] = useState<Highcharts.PointOptionsObject[]>([]);
	const [serviceVisible, setServiceVisible] = useState(false);
	const [userVisible, setUserVisible] = useState(false);
	const [unresolvedServiceVisible, setUnresolvedServiceVisible] = useState(false);
	const [unresolvedUserVisible, setUnresolvedUserVisible] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(new Set());
	let location = useLocation();

    // Parse existing query parameters and initialize state



	function filterZeroData(data) {

		return data.filter(point => point.y > 0 || point?.name.startsWith('Non Human Identities (NHI)') || point?.name.startsWith('Users') || point?.name.startsWith('Unresolved Users') || point?.name.startsWith('Unresolved Non Human Identities (NHI)'));
	}

	function filterZeroChartData(data) {
		if (customData.title === 'ASSETS') {
			return data.filter(point => 
				point.element_count > 0 && (!point.type || !point.type.startsWith('Non Human Identities (NHI)'))
			);
		} else {
		return data.filter(point => point.element_count > 0);
		}
	}

	function getYValue(chartData, ob, serviceVisible, userVisible, unresolvedServiceVisible, unresolvedUserVisible) {
		const dataPoint = chartData[ob];

		if (dataPoint?.is_exclude) {
			return dataPoint?.element_count_internal;
		}

		const type = dataPoint?.type;

		if(customData.title === 'IDENTITIES'){

		// Logic for "Non Human Identities (NHI)" and "unresolved Non Human Identities (NHI)"
		if (type?.startsWith('Non Human Identities (NHI)') && serviceVisible) {
			return 0;
		}

		// Logic for "Human Identities" and "unresolved Human Identities"
		if (type?.startsWith('Users') && userVisible) {
			return 0;
		}

		// Logic for "unresolved Non Human Identities (NHI)"
		if (type === 'Unresolved Non Human Identities (NHI)' && unresolvedServiceVisible) {
			return 0;
		}

		// Logic for "unresolved Human Identities"
		if (type === 'Unresolved Users' && unresolvedUserVisible) {
			return 0;
		}
	}

		// Default logic (when none of the conditions are met)
		return dataPoint?.element_count || 0;
	}

	const transformData = (data) => {
		if(customData.title !== 'ACCESSES'){
		return data.map((item) => {
		  let updatedType = item.type;
	  
		  // Map the `type` field based on the given conditions
		  if (updatedType === "Service/*") {
			updatedType = "Non Human Identities (NHI)";
		  } else if (updatedType === "User/*") {
			updatedType = "Users";
		  } else if (updatedType === "Service/Application") {
			updatedType = "Application";
		  } else if (updatedType === "Service/Service Account") {
			updatedType = "Service";
		  }
	  
		  // Replace `Unresolved` prefix if present in `type`
		  if (updatedType.startsWith("Unresolved Service/*")) {
			updatedType = "Unresolved Non Human Identities (NHI)";
		  } else if (updatedType.startsWith("Unresolved User/*")) {
			updatedType = "Unresolved Users";
		  } else if (updatedType.startsWith("Unresolved Service/Service Account")) {
			updatedType = "Unresolved Service";
		  } else if (updatedType.startsWith("Unresolved Service/Application")) {
			updatedType = "Unresolved Application";
		  }
	  
		  return {
			...item,
			type: updatedType,
		  };
		});
	} else {
		return data.map(item => {
			let type = item.type;
		  
			// Replace 'User/*' before the '-' with 'Human Identities'
			type = type.replace(/^User\/\*/, "Users");
		  
			// Replace 'Service/*' before the '-' with 'Non Human Identities (NHI)'
			type = type.replace(/^Service\/\*/, "Non Human Identities (NHI)");
		  
			// Replace 'Service/*' after the '-' with 'service'
			type = type.replace(/-Service\/\*/, "-Services");
		  
			return { ...item, type };
		  });
	}
	  };



	const calcData = () => {
		let innerData = [];
		let outerData = [];
		let innerCloneData: any = [];
		let innerCloneEmptyIssues: any = [];
		let colorsData: any = [];
		let index = 0;
		let chartData: any = filterZeroChartData(transformData(issues));
		let colorsOuterData = [
			"#cdcdcd", // Light gray
			"#a9a9a9", // Noticeably darker light gray
			"#858585", // Mid-tone gray
			"#6b6b6b", // Medium-dark gray
			"#525252", // Darker gray
			"#3a3a3a", // Even darker gray
			"#2a2a2a", // Very dark gray
			"#1a1a1a", // Near-black gray
			"#0a0a0a"  // Almost black
		];
		if (chartData.length <= 2) {
			colorsData = ["#959595", "#B5B5B5"]
		} else if (chartData.length <= 6) {
			colorsData = [
				"#cdcdcd", // Light gray
			"#a9a9a9", // Noticeably darker light gray
			"#858585", // Mid-tone gray
			"#6b6b6b", // Medium-dark gray
			"#525252", // Darker gray
			"#3a3a3a", // Even darker gray
			];
		} else {
			colorsData = [
				"#cdcdcd", "#cacaca", "#c5c5c5", "#bdbdbd", "#b5b5b5", "#adadad", "#a5a5a5", "#9d9d9d",
				"#959595", "#8d8d8d", "#8a8a8a", "#858585", "#7d7d7d", "#757575", "#6d6d6d", "#656565",
				"#5d5d5d", "#555555", "#4d4d4d", "#454545", "#3d3d3d", "#353535", "#2d2d2d", "#252525", "#1d1d1d", "#151515"
			];
		}
		if (customData.issueCount) {
			// Remove Jugaad Logic for legends	
			innerCloneEmptyIssues.push({
				y: 10,
				events: {
					legendItemClick: function (e: any) {
						setTimeout(() => handleWithIncidentClick());
						return true;
					}
				},
				name: "With Incidents",
				description: colorsData[2],
				id: customData.issueCount,
				color: {
					pattern: {
						path: 'M 0 0 L 7 7 M 6 0 L 7 0 M 0 6 L 0 7',
						color: "#ED4B5C",
						width: 7,
						height: 7
					}
				}
			});
		}

		// chartData.sort(function (a: any, b: any) {
		// 	var keyA = customData.is_exclude ? a.element_count_internal : a.element_count,
		// 		keyB = customData.is_exclude ? b.element_count_internal : b.element_count;
		// 	// Compare the 2 dates
		// 	if (keyA > keyB) return -1;
		// 	if (keyA < keyB) return 1;
		// 	return 0;
		// });

		chartData.sort(function (a: any, b: any) {
			// Define sorting groups based on `type`
			const groupOrder = ["Users", "User", "Unresolved Users", "Unresolved User", "Non Human Identities (NHI)","Service", "Unresolved Non Human Identities (NHI)"]; // Adjusted group order

			// Determine the group index for each type
			const getGroupIndex = (type: string) => {
				if (type === "Users") return groupOrder.indexOf("Users");
				if (type === "User") return groupOrder.indexOf("User");
				if (type.startsWith("User/")) return groupOrder.indexOf("User");
				if (type === "Unresolved Users") return groupOrder.indexOf("Unresolved Users");
				if (type.startsWith("Unresolved User")) return groupOrder.indexOf("Unresolved User");
				if (type === "Non Human Identities (NHI)") return groupOrder.indexOf("Non Human Identities (NHI)");
				if (type.startsWith("Application")) return groupOrder.indexOf("Service");
				if (type.startsWith("Service")) return groupOrder.indexOf("Service");
				if (type.startsWith("Device")) return groupOrder.indexOf("Service");
				return groupOrder.indexOf("Unresolved Non Human Identities (NHI)");
			};

			// Get group indices for `a` and `b`
			const groupA = getGroupIndex(a.type);
			const groupB = getGroupIndex(b.type);

			// First, sort by group order
			if (groupA !== groupB) return groupA - groupB;

			// Within the same group, sort alphabetically by `type`
			const nameComparison = a.type.localeCompare(b.type);
			if (nameComparison !== 0) return nameComparison;

			// As a fallback, sort by `element_count` or `element_count_internal`
			const keyA = customData.is_exclude ? a.element_count_internal : a.element_count;
			const keyB = customData.is_exclude ? b.element_count_internal : b.element_count;

			return keyB - keyA; // Sort descending within the group if all else is equal
		});




		for (let ob in chartData) {
			if (chartData[ob].type != "") {

				if(customData.title !== "ACCESSES"){

				const isServiceData = (chartData[ob].type.startsWith("Application") || chartData[ob].type.startsWith("Device") || chartData[ob].type.startsWith("Service")) && !chartData[ob].type.startsWith("Non Human Identities (NHI)");  // Check if the type starts with "Service/"
				const isUserData = chartData[ob].type.startsWith("User") && !chartData[ob].type.startsWith("Users");
				const isUnresolvedServiceData =(chartData[ob].type.startsWith("Unresolved Application") || chartData[ob].type.startsWith("Unresolved Device")  || chartData[ob].type.startsWith("Unresolved Service")) && !chartData[ob].type.startsWith("Unresolved Non Human Identities (NHI)");  // Check if the type starts with "Service/"
				const isUnresolvedUserData = chartData[ob].type.startsWith("Unresolved User") && !chartData[ob].type.startsWith("Unresolved Users");

				// Add to inner and outer data based on visibility state
				if (isServiceData && !serviceVisible) {
					continue;
				}

				if (isUserData && !userVisible) {
					continue;
				}

				if (isUnresolvedUserData && !unresolvedUserVisible) {
					continue;
				}

				if (isUnresolvedServiceData && !unresolvedServiceVisible) {
					continue;
				}
			}


				innerData.push({
					y: customData.is_exclude ? chartData[ob].element_count_with_issues_internal : chartData[ob].element_count_with_issues,
					name: chartData[ob].type,
					description: colorsData[index],
					color: colorsData[index]
				})
				innerCloneData.push({
					y: customData.is_exclude ? chartData[ob].element_count_with_issues_internal : chartData[ob].element_count_with_issues,
					name: chartData[ob].type,
					description: colorsData[index],
					color: {
						pattern: {
							path: 'M 0 0 L 8 8 M 7 0 L 8 0 M 0 7 L 0 8',
							color: "#ED4B5C",
							width: 8,
							height: 8
						}
					}
				})
				outerData.push({
					y: getYValue(
						chartData,
						ob,
						serviceVisible,
						userVisible,
						unresolvedServiceVisible,
						unresolvedUserVisible
					),
					name: chartData[ob].type == 'Unknown User' ? 'Unresolved User' : chartData[ob].type,
					description: !serviceVisible && !userVisible && !unresolvedServiceVisible && 
					!unresolvedUserVisible ? colorsOuterData[index]  : colorsData[index],
					id: customData.is_exclude ? chartData[ob].element_count_internal : (chartData[ob]?.element_count || 0),
					color: (!serviceVisible && !userVisible && !unresolvedServiceVisible && 
					!unresolvedUserVisible ) || (customData.title === 'ASSETS') ? colorsOuterData[index]  : colorsData[index],
					showInLegend: true
				})
			}
			index++;

		}

		const filteredChartOuterData = filterZeroData(outerData);
		const filteredChartInnerData = filterZeroData(innerData);
		const filteredChartInnerCloneData = filterZeroData(innerCloneData);
		const filteredChartInnerEmptyIssues = filterZeroData(innerCloneEmptyIssues)
		//Calculate Incidents Data
		setChartInnerData([]);
		setChartInnerCloneData([]);
		setChartInnerEmptyIssues([]);
		//Calculate Flows Data 
		setChartOuterData([]);

		setTimeout(() => {
			setChartInnerData(filteredChartInnerData);
			setChartInnerCloneData(filteredChartInnerCloneData);
			setChartInnerEmptyIssues(filteredChartInnerEmptyIssues);
			//Calculate Flows Data 
			setChartOuterData(filteredChartOuterData);

		}, 5)

	}

	const handleServiceToggle = () => {
		setServiceVisible(prevState => !prevState);  // Toggle the visibility state
	}

	const handleUserToggle = () => {
		setUserVisible(prevState => !prevState);  // Toggle the visibility state
	}

	const handleUnresolvedUserToggle = () => {
		setUnresolvedUserVisible(prevState => !prevState);  // Toggle the visibility state
	}

	const handleUnresolvedServiceToggle = () => {
		setUnresolvedServiceVisible(prevState => !prevState);  // Toggle the visibility state
	}

	useEffect(() => {
		if (issues) {
			calcData();
		}
	}, [customData.is_exclude, serviceVisible, userVisible, unresolvedServiceVisible, unresolvedUserVisible])

	useEffect(() => {
        // Check the condition and update the state
        if (customData?.title === "ASSETS") {
            setServiceVisible(true);
        } 
    }, [customData]); 

	const handleWithIncidentClick = () => {
		if (customData.title === "IDENTITIES") {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password,Suspected AD Pass-the-Ticket Attack");
		} else if (customData.title === "ASSETS")
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Suspicious Inbound Access,Exposed Assets,Shadow Assets,Shadow Access,Lack of MFA,Auth Protocol Quality,Unknown SaaS Access");
		else if (customData.title == 'ACCESSES') {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open");
		} else if (customData.title == 'IDENTITY SYSTEMS') {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Auth Protocol Quality,Shadow Identity Systems,Auth Hash Quality,Auth Hash Security,Suspected Directory/IdP Password Spray Attack,Suspected Directory/IdP Bot Attack,Suspected AD NTLM Relay Attack,Enumeration of AD Admins,Enumeration of AD Users, Suspected AD Golden Ticket Attack" );
		}
	}

	const options: Highcharts.Options = {
		chart: {
			width: window.matchMedia("(min-width: 2560px)").matches ? 630 : 630,
			height: window.matchMedia("(min-width: 2560px)").matches ? 180 : 180,
			type: 'pie',
			style: {
				fontFamily: 'Metropolis-Regular',
			},

		},
		credits: {
			enabled: false
		}
	};

	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

	useLayoutEffect(() => {
		const params = new URLSearchParams(location.search);
        const reportTypes = params.get("reportType")?.split(",") || [];
        setSelectedFilters(new Set(reportTypes));
		//console.log(customData.totalCount)
		setChartOptions(
			{
				...chartOptions,
				chart: {
					animation: false, // Disable animations globally
				},
				title: {
					useHTML: true,
					text:
						`<div class="ia_center_area"><span class="font16">${getRoundedUnit(customData?.totalCount || 0)}</span>` +
						`<br><span class="font8"> ${customData.subtitle}</span></div>` +

						`<div class="ia_center_area margintop5"><span class="ia-tooltip-hover font16" style="color:#f28490;">${getRoundedUnit(customData?.issueCount || 0)} </span>` +
						`<br><span class="font8" style="color:#f28490;">with incidents</span></div>`,
					style: {
						fontFamily: "Metropolis-Regular",
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "12px" : "12px",
						color: "#000000",
					},
					verticalAlign: 'middle',
					floating: false,
					x: window.matchMedia("(min-width: 2560px)").matches ?
						(chartOuterData?.length == 0 && chartInnerData?.length == 0 ? 190 : 190) : 190,
					y: window.matchMedia("(min-width: 2560px)").matches ? 12 : 12,
				},
				tooltip: {
					shared: true,
					shadow: false,
					padding: 0,
					borderRadius: 0,
					borderWidth: 0,
					backgroundColor: 'transparent',
					useHTML: true,
					formatter: function () {
						let points: any = this?.point;
						let tooltipMarkup = "<table class='ia-tooltip-content'>";
						tooltipMarkup += "<tr style='color:" + (points.total === customData.issueCount ? '#ED4B5C' : '') + "'><td style='background-color:" + points.description +
							"'>&nbsp</td><td class='ia_tooltip_percent'><b>" + Math.round(points.percentage) + "%</b></td><td style='border-left:1px solid grey;'><b>" +
							getRoundedUnit(points.y) + '</b></td><td>' +
							points.name + 
							(!points.name.endsWith('s') ? 's' : '') + 
							(points.series.options.description === "Inner" ? ' with incidents' : '') + 
							'</td></tr>';
						tooltipMarkup += "</table>"
						return tooltipMarkup;
					}
				},
				legend: {
					enabled: true,
					layout: 'vertical',
					align: 'left',
					// floating: true,					
					y: getResolutionMatch(60, 20),
					verticalAlign: 'top',
					itemMarginTop: window.matchMedia("(min-width: 2560px)").matches ? 2 : 2,
					symbolRadius: 0,
					useHTML: true,
					className: 'scrollbar-container smry-widget-legend-container',
					symbolPadding: 0,
					symbolWidth: 0,
					symbolHeight: 0,
					labelFormatter: function () {
						const current_count: any = this.options.id;
						const total_count: any = customData.totalCount;
						const percent: any = (current_count / total_count) * 100;
						if (percent === 0) {
							return '';
						}
						const getClassName = (name) => {
							if(customData.title === 'IDENTITIES'){
						        if ((name.startsWith('Device') || name.startsWith('Service') || name.startsWith('Application') )&& !name.includes('Non Human Identities (NHI)')) {
									return 'service-class';  // Add your specific class for Service
								} else if (name.startsWith('User') && !name.includes('Users')) {
									return 'service-class';  // Add your specific class for User
								} else if (name.startsWith('Unresolved User') && !name.includes('Unresolved Users')) {
									return 'service-class';  // Add your specific class for User
								} else if (name.startsWith('Unresolved Service') && !name.includes('Unresolved Non Human Identities (NHI)')) {
									return 'service-class';  // Add your specific class for User
								} else if (name.startsWith('With Incidents')) {
									return 'incident-class incident-service-class'
								} 
						}  else {
							if (name.startsWith('With Incidents')) {
								return 'incident-class'
							} 
							return '';
						}
							// Default, no additional class
						};
						const isActive = reportTypes?.includes(this.name); // `selectedFilters` is the state to track active filters
						const additionalClass = isActive ? 'active-legend' : ''; // Add `active-legend` class if active
					
						const isUserOrService = this.name.match(/^(Users|Non Human Identities \(NHI\)|Unresolved Users|Unresolved Non Human Identities \(NHI\))$/);
						const visibilityState =
							(this.name === "Users" && userVisible) ||
							(this.name === "Non Human Identities (NHI)" && serviceVisible) ||
							(this.name === "Unresolved Users" && unresolvedUserVisible) ||
							(this.name === "Unresolved Non Human Identities (NHI)" && unresolvedServiceVisible);
						// return this.name + ' ( ' + (this.options?.id || 0) + ' | ' + Math.round(percent || 0) + '%)';

						return `<div class="square-widget-container ${getClassName(this.name)} " > 
						 ${isUserOrService ? (visibilityState ? "<span class='arrow-down'>▼</span>" : "<span class='arrow-up'>▶</span>") : ""}
						 <div class="square-widget" style="background-color:${this?.color ? this?.color : '#url(#highcharts-pattern-32-443b540ee59fab0)'}">
						 </div>
						<div class="square-widget-label " title="(${this.name}) (${this.options.id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  |  ${Math.round(percent)}%)"> 
						
						  <span  class="${additionalClass}">  ${this.name} (${this.options.id.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  |  ${Math.round(percent)}%) </span> 
						</div>`;
					},
					squareSymbol: true,
					navigation: {
						enabled: false
					},
					// symbolPadding: 0,
					// symbolWidth: 0,
					// symbolHeight: 0,
					width: window.matchMedia("(min-width: 2560px)").matches ? 320 : 320,
					itemStyle: {
						fontWeight: "normal",
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "11px" : "11px",
						lineHeight: window.matchMedia("(min-width: 2560px)").matches ? "12px" : "12px",
					},
					// events: {
					// 	itemClick: function (e) {
					// 		const reportType: any = e?.legendItem?.name;

					// 		if (customData.title === "IDENTITIES" && reportType === "User") {
					// 			console.log("ASDFASDF")
					// 			history.push({
					// 				search: `page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A${reportType}`,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Unresolved User") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUser",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Service") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AService",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Service") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AService",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES") {
					// 			history.push({
					// 				search: "page=1&q=identity_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		}
					// 		if (customData.title === "ASSETS")
					// 			history.push({
					// 				search: "page=1&q=asset_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		if (customData.title === "ACCESSES") {
					// 			const identity_type: any = reportType.split("-")[0]
					// 			const asset_type: any = reportType.split("-")[1]
					// 			history.push({
					// 				search: "page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		}
					// 		if (customData.title === "IDENTITY SYSTEMS")
					// 			history.push({
					// 				search: "page=1&q=dir_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		return false;
					// 	}
					// }
				},
				series: [{
					type: "pie",
					center: ['60%', '50%'],
					showInLegend: true,
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110,
					size: window.matchMedia("(min-width: 2560px)").matches ? 155 : 155,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					data: chartOuterData, // Use filtered data
					animation: false,
					cursor: "pointer",
					point: {
						events: {
							click: function (event) {

								const reportType: any = this.options.name;

								if (customData.title === "IDENTITIES" && reportType === "Users") {
									return history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType.startsWith("User") && !reportType.startsWith("Users")) {
									history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Users") {
									return history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing",
										state: { breadcrumbId: 'IdentityPosture' }
									});

								} else if (customData.title === "IDENTITIES" && reportType.startsWith("Unresolved User") && !reportType.startsWith("Unresolved Users")) {
									return history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3A" + reportType.replace("Unresolved ", ""),
										state: { breadcrumbId: 'IdentityPosture' }
									});

								} else if (customData.title === "IDENTITIES" && reportType === "Non Human Identities (NHI)") {
									history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType.startsWith("Service") && !reportType.startsWith("Non Human Identities (NHI)")) {
									history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								}
								else if (customData.title === "IDENTITIES" && reportType === "Unresolved Non Human Identities (NHI)") {
									history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType.startsWith("Unresolved Service") && !reportType.startsWith("Unresolved Non Human Identities (NHI)")) {
									history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3A" + reportType.replace("Unresolved ", ""),
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES") {
									return history.push({
										search: "page=1&q=identity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "ASSETS") {
									if (reportType === "Non Human Identities (NHI)") {
										return history.push({
											search: "page=1&q=asset_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29",
											state: { breadcrumbId: 'IdentityPosture' }
										});
									}
									else {
										return history.push({
											search: "page=1&q=asset_type%3A" + reportType,
											state: { breadcrumbId: 'IdentityPosture' }
										});
									}
								} else if (customData.title === "ACCESSES") {
									let identity_type: any = reportType.split("-")[0]
									let asset_type: any = reportType.split("-")[1]
									if (identity_type === 'Users') {
										identity_type = 'Users%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing';
									} else if (identity_type === 'Non Human Identities (NHI)') {
										identity_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29'
									}
									if (asset_type === 'Non Human Identities (NHI)') {
										asset_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29';
									}
									return history.push({
										search: "page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type,
									});
								} else if (customData.title === "IDENTITY SYSTEMS")
									return history.push({
										search: "page=1&q=dir_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
							},
							legendItemClick: function (e) {
								const reportType: any = this.options?.name;
								const params = new URLSearchParams(location.search);

								

								if (e.browserEvent.target?.tagName === 'SPAN' && (e.browserEvent.target?.classList.contains('arrow-down') || e.browserEvent.target?.classList.contains('arrow-up'))) {

									if (reportType === 'Non Human Identities (NHI)') {
										handleServiceToggle();

									}

									if (reportType === 'Users') {
										handleUserToggle();
									}

									if (reportType === 'Unresolved Users') {
										handleUnresolvedUserToggle();
									}

									if (reportType === 'Unresolved Non Human Identities (NHI)') {
										handleUnresolvedServiceToggle();
									}
									e.preventDefault();
									return false;
								} else {

									let searchQuery = "";

									if (customData.title === "IDENTITIES" && reportType === "Users") {
                                 
										searchQuery = 'page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing';
										
									} else if (customData.title === "IDENTITIES" && reportType.startsWith("User") && !reportType.startsWith("Users")) {
										
										searchQuery = "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A" + reportType;
									} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Users") {
										
										searchQuery = "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing";

									} else if (customData.title === "IDENTITIES" && reportType.startsWith("Unresolved User") && !reportType.startsWith("Unresolved Users")) {
										

										searchQuery = "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3A" + reportType.replace("Unresolved ", "");

									} else if (customData.title === "IDENTITIES" && reportType === "Non Human Identities (NHI)") {
										

										searchQuery = "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29";
									} else if (customData.title === "IDENTITIES" && reportType.startsWith("Service") && !reportType.startsWith("Non Human Identities (NHI)")) {
										
										searchQuery = "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A" + reportType;
									}
									else if (customData.title === "IDENTITIES" && reportType === "Unresolved Non Human Identities (NHI)") {
										
										searchQuery ="page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29";
									} else if (customData.title === "IDENTITIES" && reportType.startsWith("Unresolved Service") && !reportType.startsWith("Unresolved Non Human Identities (NHI)")) {
										
										searchQuery ="page=1&q=identity_is_known%3Afalse%2Bidentity_type%3A" + reportType.replace("Unresolved ", "");
									} else if (customData.title === "IDENTITIES") {
										
										 searchQuery ="page=1&q=identity_type%3A" + reportType;
									}
									if (customData.title === "ASSETS")
										if (reportType === "Non Human Identities (NHI)") {	
											searchQuery ="page=1&q=asset_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29";
										} else {
											searchQuery ="page=1&q=asset_type%3A" + reportType;
										}
									if (customData.title === "ACCESSES") {
										let identity_type: any = reportType.split("-")[0]
										let asset_type: any = reportType.split("-")[1]
										if (identity_type === 'Users') {
											identity_type = 'Users%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing';
										} else if (identity_type === 'Non Human Identities (NHI)') {
											identity_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29'
										}
										if (asset_type === 'Non Human Identities (NHI)') {
											asset_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29';
										}

										
										if (asset_type === 'Services') {
											asset_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29';
										}
										
										 searchQuery ="page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type;
									}
									if (customData.title === "IDENTITY SYSTEMS"){	
										 searchQuery ="page=1&q=dir_type%3A" + reportType;
									}
									

									if (searchQuery) {
										const url = new URLSearchParams(searchQuery);
										url.append("reportType", reportType);
										history.push({
											search: url.toString(),
											state: { breadcrumbId: "IdentityPosture" },
										});
									}
									return false;
								}
							}
						}
					},
					dataLabels: {
						enabled: false,
						connectorShape: "straight",
						style: {
							fontSize: '1em',
							fontWeight: 'normal',
							textTransform: 'uppercase'
						}
					},
					states: {
						hover: {
							brightness: 0,
							halo: {
								size: window.matchMedia("(min-width: 2560px)").matches ? 5 : 5,
								opacity: 0.8
							},
						},
						inactive: {
							opacity: 1
						}
					}
				}, {
					type: "pie",
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70,
					size: window.matchMedia("(min-width: 2560px)").matches ? 105 : 105,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					center: ['60%', '50%'],
					dataLabels: {
						enabled: false
					},
					states: {
						hover: {
							halo: {
								size: window.matchMedia("(min-width: 2560px)").matches ? 4 : 4,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerData,
					animation: false,
					description: "Inner"
				}, {
					type: "pie",
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70,
					size: window.matchMedia("(min-width: 2560px)").matches ? 105 : 105,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					dataLabels: {
						enabled: false
					},
					cursor: "pointer",
					center: ['60%', '50%'],
					animation: false,
					point: {
						events: {
							legendItemClick: function (e) {
								return false;
							},
							click: function (event) {
								const bread = { breadcrumbId: 'PostureWithIncident', prevPath: history.location.pathname };
								if (customData.title === "IDENTITIES" && this.options.name != "Unknown User"){
									if(this.options.name === "Unresolved Users"){
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bidentity_is_known%3Afalse%2Bs_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password", bread);

									}
									if(this.options.name === "Users"){
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bs_type%3AUsers%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password", bread);
									}
									if(this.options.name === "Non Human Identities (NHI)"){
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bs_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password,Suspected AD Pass-the-Ticket Attack", bread);
									}
									if(this.options.name === "Unresolved Non Human Identities (NHI)"){
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bidentity_is_known%3Afalse%2Bs_type%3AApplication%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password", bread);
									}
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bs_type%3A" + this.options.name + '%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password', bread);
								}
								else if (customData.title === "ASSETS")
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + this.options.name + '%2Brule_name:Suspicious Inbound Access,Exposed Assets,Shadow Assets,Shadow Access,Lack of MFA,Auth Protocol Quality,Unknown SaaS Access', bread);
								else if (customData.title == 'ACCESSES') {
									if (this.options.name?.toString().includes('-')) {
										let identity_type: any = this.options.name.toString().split("-")[0]
										let asset_type: any = this.options.name.toString().split("-")[1]
										if (identity_type === 'Users') {
											identity_type = 'Users%2CUser%2CUser%2FAgent%2CUser%2FBackground%20Browsing';
										} else if (identity_type === 'Non Human Identities (NHI)') {
											identity_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29'
										}
										
										if (asset_type === 'Service') {
											asset_type = 'Application%2CDevice%2CService%2CService%2FComputer%20Account%2CService%2FKey%20and%20Secret%2CService%2FService%20Principal%2CService%2FToken%2CNon%20Human%20Identities%20%28NHI%29';
										}
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + asset_type + '%2Bs_type:' + identity_type);
									}
								}
								else if (customData.title == 'IDENTITY SYSTEMS') {
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + this.options.name + '%2Brule_name:Auth Protocol Quality,Shadow Identity Systems,Auth Hash Quality,Auth Hash Security,Suspected Directory/IdP Password Spray Attack,Suspected Directory/IdP Bot Attack,Suspected AD NTLM Relay Attack,Enumeration of AD Admins,Enumeration of AD Users ,Suspected AD Golden Ticket Attack', bread);

								}


								//	console.log(this.options.name);
							}
						},
					},
					states: {
						hover: {
							halo: {
								size: 0,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerCloneData,
					description: "Inner"
				}, {
					type: "pie",
					showInLegend: true,
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 0 : 0,
					size: window.matchMedia("(min-width: 2560px)").matches ? 0 : 0,
					minSize: 0,
					center: ['60%', '50%'],
					dataLabels: {
						enabled: false
					},
					states: {
						hover: {
							halo: {
								size: 0,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerEmptyIssues,
					animation: false,
					description: "Inner"
				}]
			}

		)

	}, [chartInnerData, chartOuterData, serviceVisible, userVisible, unresolvedServiceVisible, unresolvedUserVisible,location.search])





	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);



	return (
		<>
			<div className="idasset_chart">
				<div className="idasset_chart_title report-container-title">
					<label>{customData.title}</label>
				</div>
				<div className={`chart-container report-container ${chartInnerData.length === 0 && chartOuterData.length === 0 ? 'zero-data' : ''}`} style={{ maxWidth: '100%' }}>
					<HighchartsReact
						ref={chartRef}
						highcharts={Highcharts}
						options={chartOptions}
					/>
				</div>
			</div>
		</>
	)
}