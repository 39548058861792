import { CategoriesFields } from "../../../constants/Categories";
import { generateCategoriesForResponse, getExportFileDateTime, getLocalUser } from "../../../utils/util-methods";
import { PlaybookMap, POSTURE_ISSUE, ENTITIES } from "../constants/Constants"
import { DefaultRisk, ResetState } from "../constants/DefaultState";
import { PbFieldMap } from "../constants/PbFieldMap";
import { PlaybookAdvanceddSearchConfig } from "../constants/PlaybookAdvancedSearchConfig";
import { IPlaybook, IPlaybookRule, IPlaybookRuleState, PlaybookKeys } from "../ref/AdvancedPlaybook/PlaybookTypes";
import { IIdAssetState } from "../ref/IdPopUp";
import { IPatternConfigItem, IPatternFormValue } from "../ref/PatternForm/PatternFormGenerator";

const AM_OPS_PTRN = /^amops@[a-zA-Z0-9.-]+\.authmind$/;

export interface PlaybookListItem {
    key: any,
    value: string,
    disableOnList?: boolean
}
export const getPlaybookList = (): PlaybookListItem[] => {
    const usrString = localStorage.getItem('user');
    let userOb = { email: '' };
    if (usrString) {
        userOb = JSON.parse(usrString);
    }

    const labels: Array<any> = [];
    for (const key in PlaybookMap) {
        if ((!PlaybookMap[key].isAmOps && !AM_OPS_PTRN.test(userOb.email)) || AM_OPS_PTRN.test(userOb.email))
            labels.push({ key, value: PlaybookMap[key].label, disableOnList: PlaybookMap[key].disableOnList } as PlaybookListItem);
    }
    return labels.sort((a: PlaybookListItem, b: PlaybookListItem) => {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    });
}

export const getRiskLabel = (index: any): string => {
    const risk = [
        { id: '1', text: 'LOW_RISK_IP' },
        { id: '2', text: 'MEDIUM_RISK_IP' },
        { id: '3', text: 'HIGH_RISK_IP' },
        { id: '4', text: 'CRITICAL_RISK_IP' }
    ]
    const i = risk.find(item => item.id == index);

    return i?.text || '';
}


export const getDefaultConfigState = (ruleType: string) => {
    if (ruleType && ResetState.hasOwnProperty(ruleType)) {
        /* console.log(ResetState[ruleType as keyof typeof ResetState]) */
        if (ResetState[ruleType as keyof typeof ResetState] == undefined) {
            return ResetState[ruleType as keyof typeof ResetState]
        }
        return JSON.parse(JSON.stringify(ResetState[ruleType as keyof typeof ResetState]));
    }
    return 'NA';
}

export const getDefaultRiskByPlaybook = (ruleType: string): string => {
    try {
        if (ruleType) {
            return JSON.parse(JSON.stringify(DefaultRisk[ruleType as keyof typeof DefaultRisk]));
        }
    } catch (e) {
        console.log('Error while parsing risk for playbook. Pls define if not already')
    }

    return '2';
}

export const deTransformPbIdAssetCustomList = (state: IIdAssetState) => {
    const keys = Object.keys(state?.pattern);
    const result = keys.map((i: string): IPatternFormValue => {
        const rs = {
            searchKey: i,
            value: '',
            operator: state.pattern[i].type,
        };

        const config = getConfigItemByName(i);
        if (config?.valueType === 'boolean') {
            rs.value = `${state.pattern[i].value}`;
        } else if (config?.type === 'list' && !Array.isArray(state.pattern[i].value)) {
            rs.value = state.pattern[i].value.toString().split(',');
        } else {
            rs.value = state.pattern[i].value;
        }

        if (Array.isArray(rs.value) && ['array-autocomplete', 'multi-select', 'list', 'string-array'].includes(config?.valueType || '')
            && config?.type != 'text'
        ) {
            rs.value = rs.value.map((i) => ({ key: i?.key || i, value: i?.value || i }))
        }
        return rs;
    }) as IPatternFormValue[];
    return result;
}

export const loadFieldsByIssueType = (ruleType: string, groupId: string) => {
    const filteredFields = JSON.parse(JSON.stringify(PlaybookAdvanceddSearchConfig.filter((field: IPatternConfigItem) => field.groupId == groupId)));
    const issueType = ruleType as keyof typeof PbFieldMap;
    if (issueType && PbFieldMap[issueType]) {
        const fieldMap = PbFieldMap[issueType];
        const filterByPb = filteredFields.
            map((field: IPatternConfigItem) => {
                if (!field.isGroupRoot && !['loc', 'flow_time', 'src_inter_ip', 'inter_name', 'inter_hostname', 'collector_name', 'is_svc_to_svc_flow',
                    'is_streaming_flow'
                ].includes(field.id)) {
                    field.label = getConfigLabelById(field.id, true)
                }
                return field;
            }).
            filter((field: IPatternConfigItem) => fieldMap.includes(field.id) || field.isGroupRoot);
        if (filterByPb.length > 0) {
            return filterByPb;
        }
    }
    return [];
}

const GroupMap = {
    'directory':
        'Directory',
    'source_identity': 'Identity/Source',
    'destination_asset': 'Asset/Destination',
    intermediary: 'Access Control'
} as any;

export const getConfigLabelById = (id: string, showCategory?: boolean) => {
    const fieldItem = PlaybookAdvanceddSearchConfig.filter((field: IPatternConfigItem) => field.id == id)[0];
    const excludeCategory = ['src_inter_ip', 'inter_hostname', 'inter_name']
    if (showCategory && !excludeCategory.includes(fieldItem.id)) {
        return (`${GroupMap[fieldItem?.groupId || '']} ` + fieldItem?.label || '')
    } else if (['s_susp_internal_scanner', 's_susp_external_scanner'].includes(fieldItem.id)) {
        return `Source ${fieldItem.label}`
    }
    return (fieldItem?.label || '')
}

export const getConfigItemByName = (name: string) => {
    return PlaybookAdvanceddSearchConfig.find(
        (i: IPatternConfigItem) => i.id === name
    );
}

export const parseRulesFromApi = (issueRulesList: Array<IPlaybookRule>, ruleType: string) => {
    const result: Array<IPlaybookRuleState> = [] as Array<IPlaybookRuleState>;
    issueRulesList.forEach((_rule: IPlaybookRule) => {
        const searches = _rule?.search_field_info?.search_field;
        const ruleConfig = { ..._rule } as any;
        delete ruleConfig.search_field_info;
        const rule = {
            identity: {
                pattern: {},
                type: ['']
            }, asset: {
                pattern: {},
                type: ['']
            }, intermediary: {
                pattern: {},
                type: ['']
            },
            config: !ResetState.hasOwnProperty(ruleType || '') ? 'NA' : { ...ruleConfig }
        } as IPlaybookRuleState;
        /*   console.log(ResetState.hasOwnProperty(ruleType || ''))
          console.log(ResetState) */
        if (searches && Object.keys(searches).length > 0) {
            Object.keys(searches).forEach((val: string) => {
                const config = getConfigItemByName(val);
                if (Array.isArray(searches[val].value) && ['list', 'multi-select'].includes(config?.type || '')) {
                    searches[val].value = searches[val].value.map((i) => ({ key: i, value: i }))
                }
                if (config?.groupId == 'destination_asset') {
                    rule.asset.pattern[val] = searches[val]
                } else if (config?.groupId == 'source_identity') {
                    rule.identity.pattern[val] = searches[val]
                } else if (config?.groupId == 'directory') {
                    rule.intermediary.pattern[val] = searches[val]
                }
            })
        }

        if (_rule?.no_identities) {
            rule.identity = {
                type: ['No Identities'], pattern: {}
            }
        } else if (Object.keys(rule.identity.pattern).length == 0) {
            rule.identity = {
                type: ['All Identities'], pattern: {}
            }
        }

        if (_rule?.no_assets) {
            rule.asset = {
                type: ['No Assets'], pattern: {}
            }
        } else if (Object.keys(rule.asset.pattern).length == 0) {
            rule.asset = { type: ['All Assets'], pattern: {} }
        }

        if (Object.keys(rule.intermediary.pattern).length == 0) {
            rule.intermediary = {
                type: ['Any Access Control'], pattern: {}
            }
        }
        result.push(rule);
    })
    /* console.log(result); */
    return result;
}

export const updateCategories = (pl: IPlaybook) => {
    pl.issue_rule_list.forEach((issue_rule) => {
        const val = issue_rule.search_field_info.search_field;
        if (val) {
            Object.keys(val).forEach((field) => {
                if (CategoriesFields.includes(field)) {
                    val[field].value = generateCategoriesForResponse(
                        val[field].value
                    );
                }
            })
        }
    })
}

export const isNewPostureIssuesPlaybook = (playbookType: string = '', entityType: string = '') => {
    return playbookType === POSTURE_ISSUE.type && ENTITIES[entityType.toUpperCase()];
}

export const getIssueNameById = (issueType) => {
    if (issueType && typeof issueType == 'string' && PlaybookMap.hasOwnProperty(issueType)) {
        return PlaybookMap[issueType.trim()]?.label;
    }
    return issueType;
}

export const exportPlaybook = (data, partnerName, issue_type) => {
    const export_metadata = {
        exported_by: getLocalUser().name || '',
        exported_at: new Date().toISOString(),
        product: partnerName
    }
    const updatedData = { export_metadata, data }
    const jsonString = JSON.stringify(updatedData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const formattedDate = getExportFileDateTime();
    const issType = issue_type.split(" ").join("_")
    const a = document.createElement('a');
    a.href = url;
    a.download = `${partnerName}_PB_${issType}_${formattedDate}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export function validateAgainstInterface(data: any): boolean {
    if (data) {
        return PlaybookKeys.some((key) => key in data);
    }
    return false;
}