import React from 'react';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';

const AMTagInput = (props: any) => {
    return (
        <TagsInput
            {...props}
            pasteSplit={(data) =>
                data
                    .split(/,|\n|\s+/)
                    .map(tag => tag.trim()) 
                    .filter(tag => tag) 
            }
            addOnPaste={true}
            addKeys={[9, 13, 188, 32, 10]}
        />
    );
};

export default AMTagInput;
