import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Dialog, DialogContent, DialogTitle, Tab } from "@mui/material";
import AMMultiSelectGroup from "../../../components/core/AMMultiselectGroupable/AMMultiSelect";
import { useEffect, useRef, useState } from "react";
import { Api } from "../../../components/Axios";
import DragAndDropList from "./DragDropList";
import { deepCompare } from "../../../utils/util-methods";
import { useToasts } from "../../../components/core";
import Draggable from "react-draggable";
import { getIssueNameById } from "../../playbooks/helpers/playbook-helper";
import React from "react";

import AMTabs, { TabData } from '../../../components/core/AMTab/AMTab';

type InputData = {
  id_access_infra: {
    is_playbook_view: boolean;
    playbook_view: Record<any, any>;
  };
  shadow_activity: {
    is_playbook_view: boolean;
    playbook_view: Record<any, any>;
  };
  unauthorised_access: {
    is_playbook_view: boolean;
    playbook_view: Record<any, any>;
  };
};

type OutputData = {
  id_access_infra: string[];
  shadow_activity: string[];
  unauthorised_access: string[];
};

type Props = {
  openModal: boolean;
  handleClose: () => void;
  getData: (updatedItems: any) => void;
  selectedItems?: any;
  widId: string;
};

export const PlayBookView = (props: Props) => {
  const [playBookData, setPlayBookData] = useState<any>({});
  const { addToast } = useToasts();

  const [groups, setGroup] = useState<{ [widgetId: string]: any[] }>({
    [props.widId]: [],
  });

  const defaultValues: any = {};

  const [selectedItems, setSelectedItems] = useState<any>({ identity: [], identity_default_view: true },
    { asset: [], asset_default_view: true },
    { directory: [], directory_default_view: true }
  );

  const [dbselectedItems, setDBSelectedItems] = useState<{
    [widgetId: string]: string[];
  }>({});

  const handleSelect = (selectedItems: { [widgetId: string]: string[] }) => {
    const updatedItems = selectedItems[props.widId].map((item: Element, index: number) => {
      return { ...item, order: index };
    });
    setDoneClick(true);

    props.getData(updatedItems);
    const key = props.widId + '_default_view';
    setSelectedItems((prevSelectedItems: any) => ({
      ...prevSelectedItems,
      [props.widId]: updatedItems,
      [key]: false
    }));
  };

  const handleClear = (selectedItems: { [widgetId: string]: string[] }) => {
    props.handleClose();

  };

  const [searchKeyword, setSearchKeyword] = useState<any>("");
  const [clearKeyword, setClearKeyword] = useState<any>(false);
  const currentSearchKeywordRef = useRef(searchKeyword);

  const [elements, setElements] = useState([]);

  const findNameById = (array: ArrayItem[], idToFind: string): string => {
    for (const obj of array) {
      const foundItem = obj.items.find((item) => item._id === idToFind);
      if (foundItem) {
        return foundItem.name;
      }
    }
    return idToFind;
  }

  // const [selectedItems,setSelectedItems]= useState({identity:[{'_id':'Compromised Password','is_issue_type':true},{'_id':'Compromised User','is_issue_type':true},{'_id':'Weak Password','is_issue_type':true},{'_id':'Suspicious Outbound Access','is_issue_type':true},{'_id':'Access to Unauthorized Countries','is_issue_type':true},{'_id':'Access to Public VPN','is_issue_type':true},{'_id':'Access to Anonymous IP','is_issue_type':true},{'_id':'Suspected Directory/IdP Identity Brute-force Attack','is_issue_type':true},{'_id':'Repeated AD Login Attempts at Invalid Time','is_issue_type':true},{'_id':'Repeated AD Login Attempts from Invalid Device','is_issue_type':true},{'_id':'Suspected Attack on Expired AD Account','is_issue_type':true},{'_id':'Suspected Attack on Disabled AD Account','is_issue_type':true},{'_id':'Suspected Attack on Locked AD Account','is_issue_type':true}], identity_default_view:true});


  const renderGroupLabel = (groupId) => {
    return getIssueNameById(groupId)
  }


  const getPlayBookSelectionData = () => {
    return selectedItems[props.widId]?.map((item: any) => ({
      '_id': item._id,
      'is_issue_type': item.is_issue_type,
      'order': item.order,
      'name': item.is_issue_type ? item._id : item.name
    }));
  };

  const [doneClick, setDoneClick] = useState<boolean>(false);

  useEffect(() => {
    if (!deepCompare(selectedItems, { identity: [{ '_id': 'Compromised Password', 'is_issue_type': true }, { '_id': 'Compromised User', 'is_issue_type': true }, { '_id': 'Weak Password', 'is_issue_type': true }, { '_id': 'Suspicious Outbound Access', 'is_issue_type': true }, { '_id': 'Access to Unauthorized Countries', 'is_issue_type': true }, { '_id': 'Access to Public VPN', 'is_issue_type': true }, { '_id': 'Access to Anonymous IP', 'is_issue_type': true }, { '_id': 'Suspected Directory/IdP Identity Brute-force Attack', 'is_issue_type': true }, { '_id': 'Repeated AD Login Attempts at Invalid Time', 'is_issue_type': true }, { '_id': 'Repeated AD Login Attempts from Invalid Device', 'is_issue_type': true }, { '_id': 'Suspected Attack on Expired AD Account', 'is_issue_type': true }, { '_id': 'Suspected Attack on Disabled AD Account', 'is_issue_type': true }, { '_id': 'Suspected Attack on Locked AD Account', 'is_issue_type': true }], identity_default_view: true })) {
      if (doneClick) {
        const key = props.widId + '_default_view';
        const obj = { [key]: false };

        updateUserSelection({ [props.widId]: getPlayBookSelectionData(), ...obj });
      }
    }
  }, [selectedItems, doneClick]);



  const updateUserSelection = (data: any) => {

    Api.post("/posture/playbookview/" + props.widId, data)
      .then((res: { data: any }) => {
      })
      .catch((error: any) => {
        // setLoading(false)
        if (error.response.status === 500) {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (error.response.status === 404) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })

        } else if (error.response.status === 400) {
          addToast(error.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      });

  };


  const fetchPlayBookData = async () => {
    await Api.get("/pbviewplaybooks?type=" + props.widId, {
      timeout: 30000,
    })
      .then((res) => {
        //res.data = responseAdapter(res.data);
        setPlayBookData(res.data);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const fetchUserSelection = async () => {
    await Api.get("/dashboard/playbookview", {
      timeout: 30000,
    })
      .then((res) => {
        console.log(res.data.result);
        

        const data: any = res.data.result;
        localStorage.setItem('canEdit',data.user_can_edit_config);
        
        const { _id, ...restData } = data || {};
        setDBSelectedItems(restData);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });
  };

  const isObjectNotEmpty = (obj) => {
    return Object.keys(obj).length > 0;
  };

  useEffect(() => {
    fetchPlayBookData();
    fetchUserSelection();
  }, []);

  useEffect(() => {
    if (isObjectNotEmpty(playBookData) && isObjectNotEmpty(dbselectedItems)) {
      setGroupData();
    }
  }, [playBookData, dbselectedItems]);


  const isIdPresent = (data: any, id: string) => {
    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (item._id === id) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const setGroupData = () => {
    for (const key in playBookData) {
      if (playBookData.hasOwnProperty(key)) {
        setGroup((prevGroup) => {
          // Using the spread operator to create a copy of the previous state
          const updatedGroup = { ...prevGroup };

          // If the group[element] doesn't exist, initialize it as an empty array
          if (!updatedGroup[props.widId]) {
            updatedGroup[props.widId] = [];
          }

          // Push each datakey to the corresponding group

          playBookData[key].playbooks = playBookData[key].playbooks.map(
            (playbook: any) => ({
              ...playbook,
              entity_type: playBookData[key].entity_type,
              is_globally_added: isIdPresent(dbselectedItems, playbook._id),
            })
          );

          updatedGroup[props.widId].push({
            name: key,
            items: playBookData[key].playbooks,
            entity_type: playBookData[key].entity_type,
            is_globally_added: isIdPresent(dbselectedItems, key) || (key === 'Compromised User' || key === 'Compromised Password' || key === 'Weak Password'),
          });

          return updatedGroup;
        });
      }
    }
  };


  useEffect(() => {
    if (groups[props.widId].length > 0) {
      setTimeout(() => {
        fetchUserWidgetSelection();

      }, 300)

    }

  }, [groups]);



  const handleOnSearchKeyUp = (evt: any, clear: any = false) => {
    localStorage.removeItem("saas_state");
    setSearchKeyword(evt);
    currentSearchKeywordRef.current = evt;
    setClearKeyword(clear);
  };

  const setDefaultArray = (inputArray: any) => {
    return inputArray.map((item: any) => ({
      _id: item.name,
      is_issue_type: true
    }));

  }

  const fetchUserWidgetSelection = async () => {

    await Api.get('/posture/playbookview/' + props.widId, {
      timeout: 30000,
    })
      .then((res) => {
        const data: any = res.data.result;

        const key = props.widId + '_default_view';

        const { _id, ...restData } = data || {};

        !data[key] ? setSelectedItems((prevSelectedItems: any) => ({
          ...prevSelectedItems,
          [props.widId]: restData[props.widId] ? restData[props.widId] : [],
          [key]: false
        })) : setSelectedItems((prevSelectedItems: any) => ({
          ...prevSelectedItems,
          [props.widId]: setDefaultArray(groups[props.widId]),
          [key]: true
        }));

        if (data[key]) {
          defaultValues[props.widId] = setDefaultArray(groups[props.widId]);
        }

        const disabledItemsAndGroups = groups[props.widId].flatMap((group: any) => {
          const groupDisabled = group.is_globally_added && !selectedItems[props.widId]?.some((selectedItem: any) => selectedItem._id === group.name);
          const itemsDisabled = group.items.filter((item: any) =>
            item.is_globally_added &&
            (
              !selectedItems[props.widId]?.some((selectedItem: any) => selectedItem._id === item._id || selectedItem._id === item.name)
            )
          );


          const { items, name, ...addGroup } = group;
          const updatedGroup = { ...addGroup, name: name, _id: name, is_issue_type: true };
          return groupDisabled ? [updatedGroup, ...itemsDisabled] : itemsDisabled;
        });

        const mergedArray = !data[key] ? [...disabledItemsAndGroups, ...Object.values(data[props.widId])] : [...disabledItemsAndGroups, ...defaultValues[props.widId]];

        // Use a dictionary to store unique elements based on _id
        const uniqueItemsDictionary: any = {};

        mergedArray.forEach(item => {
          if (item._id) {
            if (!uniqueItemsDictionary[item._id]) {
              uniqueItemsDictionary[item._id] = item;
            } else {

              uniqueItemsDictionary[item._id] = {
                ...uniqueItemsDictionary[item._id],
                ...item,
                order: data[key] ? 1 : data[props.widId].find(obj => obj._id === item._id).order || (Math.max(...Object.values(data[props.widId]).map(obj => obj.order || 0)) + 1)
              };
            }
          }
        });

        // Convert dictionary values back to array
        const uniqueMergedArray = Object.values(uniqueItemsDictionary);
        props.getData(uniqueMergedArray);


      })
      .catch((err) => {
        if (err.response?.status === 401) {
          window.location.href = "/login";
        }
      });

  }

  const dragContainerRef = useRef(null);
  const onControlledDrag = (e, position) => {
    let { x, y } = position;
    // top bounds check
    if (y < -10) {
      y = -10;
    }
    // bottom bounds check (leave 13% of container top area outside)
    if (y > window.innerHeight - window.innerHeight * 0.13) {
      y = window.innerHeight - window.innerHeight * 0.13;
    }
    // right bounds check (leave 7% of container right area outside)
    if (
      x + dragContainerRef.current.clientWidth >
      window.innerWidth - window.innerWidth * 0.07
    ) {
      x =
        window.innerWidth -
        dragContainerRef.current.clientWidth -
        window.innerWidth * 0.07;
    }

    // left bounds check
    if (x < -(window.innerWidth - 100)) {
      x = -(window.innerWidth - 100);
    }
    setControlledPosition({ x, y });
  };

  const onControlledDragStop = (e, position) => {
    onControlledDrag(e, position);
  };
  const [controlledPosition, setControlledPosition] = useState({
    x: 0,
    y: 0
  });

  const handleDoneClick = () => {

    props.handleClose();
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleClose = () => {
    props.handleClose();
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleDropDoneClick = (widId: string, items: any) => {
    const updatedItems = items.map((item: Element, index: number) => {
      return { ...item, order: index };
    });
    setDoneClick(true);
    props.getData(updatedItems);
    const key = props.widId + '_default_view';
    setSelectedItems((prevSelectedItems: any) => ({
      ...prevSelectedItems,
      [props.widId]: updatedItems,
      [key]: false
    }));
    props.handleClose();
    setControlledPosition({ x: 0, y: 0 });
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const defaultTabs: TabData[] = [
    {
      label: "Select",
      Component: <span><Box className="sub-header" sx={{ marginBottom: "15px" }}>
        {" "}
        Select the Issues and Playbooks to include in your view. Any
        Issues and Playbooks shown in the dashboard are enabled by
        default.
      </Box>
        <AMMultiSelectGroup
          groups={groups}
          onSelect={handleSelect}
          handleClearBtn={handleClear}
          onDoneClick={handleDoneClick}
          onSearchKeyUp={handleOnSearchKeyUp}
          doneLabel="Save"
          selectedItems={selectedItems}
          widId={props.widId}
          renderGroupLabel={renderGroupLabel}
        /></span>,
      selected: false,
      title: "Select"
    },
    {
      label: "Order",
      Component: <span>
        <Box className="sub-header" sx={{ marginBottom: "15px" }}>
          Order Issue type and Playbook to view incident data
        </Box>
        <DragAndDropList
          elements={elements}
          OnCancel={handleCancel}
          onDoneClick={handleDropDoneClick}
          widId={props.widId}
        ></DragAndDropList>
      </span>,
      selected: false,
      title: "Order"
    },
  ]

  const handleChange = (newValue: string) => {
    if (newValue === "1") {
      const userDetails = localStorage.getItem('user') as string;
      const user = JSON.parse(userDetails);
      const key = user.email + '_' + props.widId;
      const myValue: string | null = localStorage.getItem(key);

      if (myValue !== null) {
        // Safely use myValue as a string here
        const element = [];
        const updatedData = JSON.parse(myValue).map((item: any) => {
          if (item.is_issue_type) {
            return { ...item, name: item._id };
          } else {
            return { ...item, name: findNameById(groups[props.widId], item._id) };
          }
        });
        setElements(updatedData);
        console.log(elements);
      } else {
        // Handle the case where the value is null (key doesn't exist in localStorage)
        console.log("Value does not exist in localStorage");
      }
    }
  };

  return (
    <Draggable
      position={controlledPosition}
      handle='.search-header-container'
      onStop={onControlledDragStop}
    >
      <Dialog
        className="am-groupable-dialog identity-dialog"
        open={props.openModal}
        slotProps={{
          backdrop: {
            onClick: (event) => event.stopPropagation(),
          },
        }}

      >
        <DialogTitle ref={dragContainerRef} className='search-header-container'>
          <strong className="groupable-title">{props.widId === 'identity' ? 'Identities' : props.widId === 'asset' ? 'Assets' : 'Identity Systems'} View Configuration</strong>
          <Box
            sx={{ float: "right", marginTop: "20px", right: 0 }}
            onClick={handleClose}
            className="popup-container-close-dashboard"
          >
          </Box>
        </DialogTitle>
        <DialogContent
        >
          <AMTabs
            tabs={defaultTabs}
            isPopupBased={true}
            onTabClick={handleChange}
          />
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};
